<template>
  <div class="merchants-preview">
    <div class="merchants-wrapper">
      <div class="merchants-preview-header">
          <h1>New merchants</h1>
          <div v-if="requested && !isError">
              <a href="/merchants" class="view-button">
              <span>See All</span>
              <v-icon color="#343a3e" >
                  {{icons.mdiChevronRight}}
              </v-icon>
          </a>
          </div>
      </div>

      <div v-if="!requested" class="loader">
          <div v-for="i in 7" :key="i" class="mr-2">
              <v-skeleton-loader
              height="130"
              width="160"
              type="image"
              ></v-skeleton-loader>
          </div>
      </div>

      <div v-if="requested">
        <div class="merchants-preview-main" v-if="!isError" @touchstart="touch" @touchend="stopTouch">
          <div class="btn-arrow arrow-desktop" @click="swipeLeft">
              <v-icon size="55" color="white">
                  {{icons.mdiChevronLeft}}
              </v-icon>
          </div>
          <v-btn icon class="btn-arrow arrow-mobile" style="left:-10px" @click="swipeLeft" elevation="5">
              <v-icon size="30" color="#ee2d24">
                  {{icons.mdiChevronLeft}}
              </v-icon>
          </v-btn>

          <div class="merchants-preview-slider" ref="scrollMerchantTab"> 

              <v-card width="150" v-for="store in stores.slice(0,10)" :key="store.id" style="text-align:start" class="merchant-preview-container" @click="clickStore(store)">
                  <v-img width="150" height="150px" :src="store.thumbnail">
                  </v-img>
                  <v-card-subtitle class="px-2 py-1" >
                      <div class="merchant-preview-store-name">
                          {{store.name}}
                      </div>
                      </v-card-subtitle>
              </v-card>
          </div>
          <v-btn icon class="btn-arrow arrow-mobile"  style="right:-10px" @click="swipeRight" elevation="5"
          >
              <v-icon size="30" color="#ee2d24">
                  {{icons.mdiChevronRight}}
              </v-icon>
          </v-btn>
          <div class="btn-arrow arrow-desktop" @click="swipeRight">
              <v-icon size="55" color="white">
                  {{icons.mdiChevronRight}}
              </v-icon>
          </div>
        </div>
        <div v-else>
            <div style="height:6rem;text-align:start;padding:1rem 0;font-size:1.1rem;color:gray">No Item Available</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mdiChevronLeft,mdiChevronRight } from '@mdi/js';
import { getStoreList } from '@/components/api/api.js'
import { mainMixin } from '@/mixins'

export default{
	name:'NewMerchants',
  data(){
      return{
          isVisibleButtonLeft: false,
          isVisibleButtonRight: true,
          isError:false,
          requested:false,
          stores:[],
          icons:{
              mdiChevronRight,
              mdiChevronLeft
          },
      }
  },
  mixins: [mainMixin],
  created(){
      this.getData()
  },
  methods: {
    clickStore(store){
        const storeId = store.id
        const origin =  "https://merchant.fastorder.my"
        const pathname= "/v202105/"
        const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&tabletype=delivery`
        const url = origin+pathname+hash
        window.location= url
        // console.log(url)
    },
    getData(){
        getStoreList()
            .then(res => {
                const indexpage = res.data
                console.log('store list=>>',indexpage)
                this.stores = indexpage
                this.requested = true
            }).catch(error => {
                console.log('error :', error)
                this.isError= true
                this.requested = true
            })
    },
    stopTouch(){
        setTimeout(()=>{
            this.handleSwipe()
        },200)
    },
    touch(){
        this.handleSwipe()
    },
    handleSwipe(){
        if(this.$refs.scrollMerchantTab == undefined) return

        if(this.$refs.scrollMerchantTab.scrollLeft > 0){
            this.isVisibleButtonLeft = true
            if(this.$refs.scrollMerchantTab.scrollLeft + this.$refs.scrollMerchantTab.clientWidth < this.$refs.scrollMerchantTab.scrollWidth -20){
                this.isVisibleButtonRight = true
            }else{
                this.isVisibleButtonRight = false
            }
        }else{
            this.isVisibleButtonLeft= false
            this.isVisibleButtonRight = true
        }
    },
    swipeLeft() {
        const content = this.$refs.scrollMerchantTab
        this.scrollTo(content, -100, 400);
    },
    swipeRight() {
        const content = this.$refs.scrollMerchantTab
        this.scrollTo(content, 100, 400);
    },

  }
}

</script>
<style lang="scss">
@import "../css/global.scss";
@import "../css/common.scss";

.merchants-preview{
    background: #f3f7f870;
    padding: 3rem 0;
    @media screen and (max-width: $bp-mobile){
        padding: 1.5rem 0 1rem;
    }
    .merchants-wrapper{
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 1rem;
    }
    .loader::-webkit-scrollbar { display: none; }
    .loader{
        display: flex;
        overflow-x: scroll;
        margin-left: 2rem;
        margin-top: 1rem;
        scrollbar-width: none !important;
        @media screen and (max-width: $bp-mobile){
            margin-left: 0;
        }
    }
    .merchants-preview-header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1{
            font-size: 30px;
            color: rgba(0, 0, 0, 0.87);
            margin-left: 2.5rem;
            font-family: "Helvetica Neue";
            @media screen and (max-width: $bp-mobile){
                font-size: 16px;
                text-align: start;
                margin-left: 1rem;
                font-weight: 500;
            }
        }
        .view-button{
            text-decoration: none;
            color: $gray1;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .merchants-preview-main::-webkit-scrollbar{
        display: none;
    }
    .merchants-preview-main{
        display: flex;
        padding: 10px 0 20px;
        align-items: center;
        position: relative;

    .btn-arrow{
        opacity: 0.6;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
    }
    .arrow-desktop{
        background: $bgColor2;
        height: 60px;
        border-radius: 5px;
        width: 40px;
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
    }
    .arrow-mobile{
        display: none;
        @media screen and (max-width: $bp-mobile){
            display: flex;
            background: #fff;
            margin: 0 0.2rem;
            position: absolute;
            opacity: 1;
            z-index: 3;
        }
    }
        .btn-arrow:hover{
            opacity: 1;
        }

        .merchants-preview-slider::-webkit-scrollbar{
            display: none;
        }
        .merchants-preview-slider{
            padding: 1rem 0;
            width: 100%;
            display: flex;
            align-items: flex-start;
            overflow-x: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            
        }
    }
    .merchant-preview-container:first-child{
        margin: 0 10px 0 2px;
        @media screen and (max-width: $bp-mobile){
            margin: 0 3px 0;
        }
    }
    .merchant-preview-container{
        cursor: pointer;
        margin: 0 10px;
        @media screen and (max-width: $bp-mobile){
            margin: 0 3px;
        }
        .merchant-preview-store-name{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height:20px;
            height:40px;
            font-size:13px
        }
        .merchant-preview-image-wrapper{
            overflow: hidden;
            height: 122px;
            width: 122px;
            box-shadow: inset 0 0 1px 1px darkgray;
            img{
                height: 100%;
                width: 100%;
            }
        }
        h3{
            font-size: 14px;
            padding-right: 5px;
            margin-top: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        span{
            display: block;
            padding-right: 10px;
            font-size: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

</style>