<template>
  <v-app>
    <v-main>
      <div id="app">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>


export default {
  created () {
    this.handleScroll()
    window.addEventListener('scroll', this.throttle(this.handleScroll, 20));
  },
  beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll);
  },
  data (){
    return {
        scrollActive:false,
        isHome:false,
    }
  },
  methods: {
    throttle(callback,limit){
        let wait = false;
        return function(){
            if(!wait){
            callback.call();
            wait =true;
            setTimeout(function (){
                wait= false;
            },limit)
            }
        }
    },
    isChatOpen(){
      setTimeout(()=>{this.handleScroll()},500)
    },  
    handleScroll(){
      const  el =document.querySelector("body .crisp-client");
      if(location.pathname != '/'){
        this.isHome = true
      }
      if(el == null) return this.isChatOpen()
      if(this.isHome){
          el.classList.add('crisp-animate')
      }else{
          if (window.scrollY > 150) {
            this.scrollActive = true;
            if(el != undefined){
              el.classList.add('crisp-animate')
              if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight-30) return el.classList.add('crisp-animate_bottom')
              el.classList.remove('crisp-animate_bottom')
            }
          }else{
            this.scrollActive = false;
            if(el != undefined) return el.classList.remove('crisp-animate')
          } 
      }
    },  
}
}
</script>

<style lang="scss">
@import "@/components/common/css/global.scss";
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.overflow-hidden{
    overflow: hidden;
  }
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.crisp-client{
  @media screen and (max-width: $bp-mobile){
      #crisp-chatbox{
        .cc-1xry , 
        .cc-1gmp{
            .crisp-client, 
            .cc-kv6t[data-full-view=true],
            .cc-1xry, 
            .cc-unoo,
            .cc-7doi{
              transition: 0.3s ease-out !important;
              height: 40px !important;
              width: 40px !important; 
              .cc-15mo{
                width: 26px !important;
                height: 26px !important;
                top: 9px !important;
                left: 7px !important;
              }
            }
        }
      }
    }
}
.crisp-animate{
  @media screen and (max-width: $bp-mobile){
      #crisp-chatbox{
        .cc-1xry , .cc-1gmp{
            .crisp-client, 
            .cc-kv6t[data-full-view=true],
            .cc-1xry, 
            .cc-unoo{
              transform: translateY(-45px);
            }
        }
      }
    }
}
.crisp-animate_bottom{
  @media screen and (max-width: $bp-mobile){
      #crisp-chatbox{
        .cc-1xry, 
        .cc-1gmp{
            .crisp-client, 
            .cc-kv6t[data-full-view=true],
            .cc-1xry, 
            .cc-unoo{
              transform: translateY(-95px) !important;
            }
        }
      }
    }
}

</style>
