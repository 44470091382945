<template>
    <div class="foods-trending">
        <div class="foods-trending-wrapper">
            <div class="trending-header">
                <h1>Trending this week</h1>
            </div>

            <div v-if="!requested" class="loader">
                <div v-for="i in 7" :key="i" class="mr-2">
                    <v-skeleton-loader
                    height="130"
                    width="160"
                    type="image"
                    ></v-skeleton-loader>
                </div>
            </div>

            <div v-if="requested">
                <div class="foods-trending-main" v-if="!isError" @touchstart="touch" @touchend="stopTouch">
                    <div class="btn-arrow arrow-desktop" @click="swipeLeft" v-if="isScrollAble">
                        <v-icon size="55" color="white">
                            {{icons.mdiChevronLeft}}
                        </v-icon>
                    </div>
                    <v-btn 
                    icon class="btn-arrow arrow-mobile" style="left:-10px" @click="swipeLeft" elevation="5">
                        <v-icon size="30" color="#ee2d24">
                            {{icons.mdiChevronLeft}}
                        </v-icon>
                    </v-btn>

                    <div class="foods-trending-slider" ref="scrollTrendTab" style="text-align:start" :style="isScrollAble ? '': 'padding-left:2rem'"> 

                        <v-card width="200" v-for=" trendFood in trendingFoods" :key="trendFood.id" class="food-trend-container" @click="clickFood(trendFood)">
                            <v-img class="white--text align-end" height="150px" width="200" :src="trendFood.thumb">
                            </v-img>

                            <v-card-subtitle class="py-2 px-2" style="color:black">
                                <div class="food-trend-name">
                                    {{trendFood.name}}
                                </div>
                            </v-card-subtitle>

                            <v-card-text class="px-2 text--primary">
                                <div class="food-trend-store-name">{{trendFood.store.name}}</div>

                            </v-card-text>
                        </v-card>

                    </div>

                    <div class="btn-arrow arrow-desktop" @click="swipeRight" v-if="isScrollAble">
                        <v-icon size="55" color="white">
                            {{icons.mdiChevronRight}}
                        </v-icon>
                    </div>
                    <v-btn icon class="btn-arrow arrow-mobile" style="right:-10px" @click="swipeRight" elevation="5"
                    >
                        <v-icon size="30" color="#ee2d24">
                            {{icons.mdiChevronRight}}
                        </v-icon>
                    </v-btn>
                </div>

                <div v-else>
                    <div style="height:6rem;text-align:start;padding:1rem 0;font-size:1.1rem;color:gray">No Item Available</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { mdiChevronLeft,mdiChevronRight } from '@mdi/js';
import { getFoodsTrending} from '@/components/api/api.js';
import { mainMixin } from '@/mixins'

export default{
    name:'FoodsTrending',
    data(){
        return{
            isVisibleButtonLeft: false,
            isVisibleButtonRight: true,
            isScrollAble: true,
            isError:false,
            requested:false,
            trendingFoods: [],
            icons:{
                mdiChevronRight,
                mdiChevronLeft
            },
        }
    },
    mixins: [mainMixin],
    created(){
        this.getData()
        setTimeout(()=>{
            this.checkHasScroll()
        },1000)

        window.addEventListener("resize", this.checkHasScroll);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkHasScroll);
    },
    methods: {
    checkHasScroll(){
        if(this.$refs.scrollTrendTab != undefined){
            if(this.$refs.scrollTrendTab && this.$refs.scrollTrendTab.scrollWidth == this.$refs.scrollTrendTab.clientWidth) {
                this.isScrollAble= false
            }else
            this.isScrollAble= true
        }
    },
    clickFood(food){
        const itemId =food.id
        const storeId = food.store.id
        const origin =  "https://merchant.fastorder.my"
        const pathname= "/v202105/"
        const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&itemid=${itemId}`
        const url = origin+pathname+hash
        window.location= url
    },
    getData(){
        var todayDate = new Date().toISOString().slice(0, 10);
        let params = {
            category : 0,
            date : todayDate
        }
        getFoodsTrending(params)
            .then(res => {
                console.log('trend food =>',res.data)
                const indexpage = res.data
                this.trendingFoods = indexpage
                this.requested = true
            }).catch(error => {
                console.log('error: ',error)
                this.isError= true
                this.requested = true
            })
    },
    stopTouch(){
        setTimeout(()=>{
            this.handleSwipe()
        },200)
    },
    touch(){
        this.handleSwipe()
    },
    handleSwipe(){
        if(this.$refs.scrollTrendTab == undefined) return

        if(this.$refs.scrollTrendTab.scrollLeft > 0){
            this.isVisibleButtonLeft = true
            if(this.$refs.scrollTrendTab.scrollLeft + this.$refs.scrollTrendTab.clientWidth < this.$refs.scrollTrendTab.scrollWidth -20){
                this.isVisibleButtonRight = true
            }else{
                this.isVisibleButtonRight = false
            }
        }else{
            this.isVisibleButtonLeft= false
            this.isVisibleButtonRight = true
        }
    },
    swipeLeft() {
        const content = this.$refs.scrollTrendTab
        this.scrollTo(content, -100, 400);
    },
    swipeRight() {
        const content = this.$refs.scrollTrendTab
        this.scrollTo(content, 100, 400);
    },
    }
}
</script>
<style lang="scss">
@import "../css/global.scss";
@import "../css/common.scss";

.foods-trending{
    padding: 3rem 0;
    @media screen and (max-width: $bp-mobile){
        padding: 1.5rem 0 1rem;
    }
    .foods-trending-wrapper{
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 1rem;
        /* @media screen and (max-width: $bp-mobile){
            padding: 0;
        } */
    }
    .trending-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1{
            font-size: 30px;
            color: rgba(0, 0, 0, 0.87);
            margin-left: 2.5rem;
            font-family: "Helvetica Neue";
            @media screen and (max-width: $bp-mobile){
                font-size: 16px;
                margin-left: 1rem;
                font-weight: 500;
            }
        }
        .view-button{
            text-decoration: none;
            color: $gray1;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .loader::-webkit-scrollbar { display: none; }
    .loader{
        display: flex;
        overflow-x: scroll;
        margin-left: 2rem;
        margin-top: 1rem;
        scrollbar-width: none !important;
        @media screen and (max-width: $bp-mobile){
            margin-left: 0;
        }
    }
    .foods-trending-main::-webkit-scrollbar{
        display: none;
    }
    .foods-trending-main{
        display: flex;
        align-items: center;
        position: relative;

        .btn-arrow{
            opacity: 0.6;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            @media screen and (max-width: $bp-mobile){
                display: none;
            }
        }
        .arrow-desktop{
            background: $bgColor2;
            height: 60px;
            border-radius: 5px;
            width: 40px;
            @media screen and (max-width: $bp-mobile){
                display: none;
            }
        }
        .arrow-mobile{
            display: none;
            @media screen and (max-width: $bp-mobile){
                display: flex;
                background: #fff;
                margin: 0 0.2rem;
                position: absolute;
                opacity: 1;
                z-index: 3;
            }
        }
        .btn-arrow:hover{
            opacity: 1;
        }

        .foods-trending-slider::-webkit-scrollbar{
            display: none;
        }
        .foods-trending-slider{
            padding: 1rem 0;
            width: 100%;
            display: flex;
            align-items: flex-start;
            overflow-x: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
        }
    }
    .food-trend-container:first-child{
        margin: 0 10px 0 2px;
        @media screen and (max-width: $bp-mobile){
            margin: 0 3px;
        }
    }
    .food-trend-container{
        margin: 0 10px;
        @media screen and (max-width: $bp-mobile){
            margin: 0 3px;
        }
        .food-trend-name{
            line-height:20px;
            height:40px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .food-trend-store-name{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color:#7a7e8a;
            font-size:12px
        }
    }
}

</style>