<template>
  <div id="footer" tag="Test">
    <div class="footer__columns">
      <div class="footer__column" id="emailColumn">
        <h5>Susbcribe to our newsletter</h5>
        <div class="emailField">
          <v-text-field dense label="Enter your email" persistent-hint outlined>
          </v-text-field>
          <button style="line-height: 0;" class="btn-hero btn-red">Subscribe</button>
        </div>
        <h5>About FastOrder.my</h5>
        <p>
          Be the first to find out about our exclusive tech products, the latest
          tech trends. We’re on a mission to build a better future with
          technologies applied in GLY Technology
        </p>
        <div class="payment">
          <div id="image_visa" class="payment__logo"></div>
          <div id="image_unionPay" class="payment__logo"></div>
          <div id="image_masterCard" class="payment__logo"></div>
        </div>
      </div>
      <div class="footer__column social">
        <h5>Social Medias</h5>
        <a href="https://www.facebook.com/fastorder.my'"
          ><font-awesome-icon :icon="['fab', 'facebook']" class="icon alt"
        /></a>
        <a href="https://www.instagram.com/fastorder.my/"
          ><font-awesome-icon :icon="['fab', 'instagram']" class="icon alt"
        /></a>
      </div>
      <div class="footer__column linksColumn">
        <h5>For Information</h5>
        <div
          v-for="information in informationList"
          :key="information.id"
          class="linksList"
        >
          <v-icon small color="black"> mdi-chevron-right </v-icon>
          <p>{{ information.text }}</p>
        </div>
      </div>
      <div class="footer__column linksColumn">
        <h5>For Restaurants</h5>
        <div
          v-for="restaurant in restaurantList"
          :key="restaurant.id"
          class="linksList"
        >
          <v-icon small color="black"> mdi-chevron-right </v-icon>
          <p>{{ restaurant.text }}</p>
        </div>
      </div>
    </div>

    <div class="footer__tag">
      © Copyright 2021 FASTORDER.MY POWERED BY <a> GLY TECHNOLOGY & IT CONSULTANCY</a> All
      Rights Reserved
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    informationList: [
      { text: "About Us" },
      { text: "Our Team" },
      { text: "Testimonials" },
    ],
    restaurantList: [
      { text: "Register as Merchant" },
      { text: "Terms and Conditions" },
      { text: "Frequently Asked Questions" },
      { text: "Privacy and Policy" },
    ],
  }),
};
</script>
<style lang="scss" scoped>
@import "@/components/common/css/global.scss";
@import "@/components/common/css/button.scss";
@import "@/components/common/css/common.scss";

#footer {
  padding: 30px;
  padding-top: 60px;
  text-align: center;
  background-color: #eaeff2;

  @media (max-width: $bp-mobile) {
    padding: 14px 14px 60px 14px;
  }
}

.footer__tag {
  margin-top: 50px;
  font-size: 14px;

  & a {
    color: $bgColor2;
    font-weight: 600;
  }
}

.footer__columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;

  // For smaller screens
  @media (max-width: $bp-tablet) {
    justify-content: left;
  }
}

.footer__column {
  margin: 20px;
  text-align: left;
  width: 300px;

  @media (max-width: $bp-mobile) {
    margin: 20px 0 0;
  }

  & h5 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  & p {
    font-size: 12px;
  }

  & .emailField {
    display: flex;

    & .btn-hero {
      height: 40px;
      font-size: 16px;
      border-radius: 10px;
      margin-left: 10px;
    }
  }
}

#emailColumn {
  width: 350px;

  & .payment {
    display: flex;
    flex-shrink: 0;

    & .payment__logo {
      width: 100px;
      height: 60px;
      background-size: contain;
      margin-right: 5px;
      text-align: center;
    }

    & #image_visa {
      background-image: url("../../assets/payment/visa.jpg");
    }
    & #image_unionPay {
      background-image: url("../../assets/payment/unionPay.png");
    }
    & #image_masterCard {
      background-image: url("../../assets/payment/masterCard.png");
    }

    @media (max-width: $bp-mobile) {
      flex-wrap: wrap;
  }
  }
}

.linksColumn {
  max-width: 200px;
  & .linksList {
    display: flex;
    margin-top: 10px;
    transition: 0.2s ease-in-out;

    & p {
      line-height: 1;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:hover {
      color: map-get($colors, accent);
    }
  }
}

.icon {
  font-size: 40px;
  margin-right: 20px;
  transition: 0.2s ease-in-out;

  &:hover {
    color: map-get($colors, accent);
  }
}

.social {
  & a {
    color: $black;
  }
}
</style>