<template>
    <div class="foods-category">
        <div class="foods-category-wrapper" @touchstart="touch" @touchend="stopTouch">
                <!-- <v-btn icon class="btn-arrow arrow-left" @click="swipeLeft" >
                    <v-icon size="30" color="#703592">
                        {{icons.mdiChevronLeft}}
                    </v-icon>
                </v-btn> -->

            <h1>Browse Categories</h1>
            <div class="foods-category-main" ref="scrollTab">

                <div class="food-category-wrapper" v-for="food in foodsCategory" :key="food.id" @click="gotoFoodCategory(food)"> 
                    <div class="img-wrap">
                        <img :src="food.thumb" :alt="food.name">
                    </div>
                    <span>{{food.name}}</span>
                </div>

            </div>
                    <!-- <v-btn icon class="btn-arrow arrow-right" @click="swipeRight" >
                        <v-icon size="30" color="#703592">
                            {{icons.mdiChevronRight}}
                        </v-icon>
                    </v-btn> -->
        </div>
    </div>
</template>
<script>
import { mdiChevronRight,mdiChevronLeft } from '@mdi/js';
import beef from "@/assets/beef.png"
import bentosets from "@/assets/bentosets.png"
import beverages from "@/assets/beverages.png"
import burger from "@/assets/burger.png"
import cakes from "@/assets/cakes.png"
import chicken from "@/assets/chicken.png"
import desserts from "@/assets/desserts.png"
import lamb from "@/assets/lamb.png"
import noodles from "@/assets/noodles.png"
import pasta from "@/assets/pasta.png"
import porkfree from "@/assets/porkfree.png"
import rice from "@/assets/rice.png"
import salad from "@/assets/salad.png"
import seafood from "@/assets/seafood.png"
import soup from "@/assets/soup.png"
import mart from "@/assets/mart.jpg"
import { mainMixin } from '@/mixins'

export default{
    name:'FoodsCategory',
    data(){
        return{
            icons:{
                mdiChevronRight,
                mdiChevronLeft
            },
            isVisibleButtonLeft: false,
            isVisibleButtonRight: true,
            foodsCategory: [
                { 
                    name: 'Pork Free',
                    thumb: porkfree
                },
                { 
                    name: 'Noodles',
                    thumb: noodles
                },
                { 
                    name: 'Bento & Sets',
                    thumb: bentosets
                },
                { 
                    name: 'Rice',
                    thumb: rice
                },
                { 
                    name: 'Pasta',
                    thumb: pasta
                },
                { 
                    name: 'Burger',
                    thumb: burger
                },
                { 
                    name: 'Soup',
                    thumb: soup
                },
                { 
                    name: 'Desserts',
                    thumb: desserts
                },
                { 
                    name: 'Seafood',
                    thumb: seafood
                },
                { 
                    name: 'Beef',
                    thumb: beef
                },
                { 
                    name: 'Lamb',
                    thumb: lamb
                },
                { 
                    name: 'Chicken',
                    thumb:  chicken
                },
                { 
                    name: 'Salad',
                    thumb: salad
                },
                { 
                    name: 'Cakes',
                    thumb: cakes
                },
                { 
                    name: 'Beverages',
                    thumb: beverages
                },{ 
                    name: 'Mart',
                    thumb: mart
                }
            ]
        }
    },
    mixins: [mainMixin],
    created(){
        this.getData();
    },
    methods: {
        getData(){
        
        },
        gotoFoodCategory(food){    
            let query = food.name.toLowerCase()
            let url= {
                path: `/category/${query}`
            }
            this.$router.push(url)
        },
    stopTouch(){
        setTimeout(()=>{
            this.handleSwipe()
        },200)
    },
    touch(){
        this.handleSwipe()
    },
    handleSwipe(){
        if(this.$refs.scrollTab == undefined) return
        
        if(this.$refs.scrollTab.scrollLeft > 0){
            this.isVisibleButtonLeft = true
            if(this.$refs.scrollTab.scrollLeft + this.$refs.scrollTab.clientWidth < this.$refs.scrollTab.scrollWidth -20){
                this.isVisibleButtonRight = true
            }else{
                this.isVisibleButtonRight = false
            }
        }else{
            this.isVisibleButtonLeft= false
            this.isVisibleButtonRight = true
        }
    },
        swipeLeft() {
            const content = this.$refs.scrollTab
            this.scrollTo(content, -100, 400);
        },
        swipeRight() {
            const content = this.$refs.scrollTab
            this.scrollTo(content, 100, 400);
        },
    }
}
</script>
<style lang="scss">
@import "../css/global.scss";
@import "../css/common.scss";

.foods-category{
    padding: 3rem 0;
    @media screen and (max-width: $bp-mobile){
        padding: 1.5rem 0 1rem;
    }
    .foods-category-wrapper{
        padding: 0 1rem;
        max-width: 1300px;
        margin: 0 auto;
        position: relative;
        text-align: start;
        h1{
            font-size: 30px;
            color: rgba(0, 0, 0, 0.87);
            margin-left: 2.5rem;
            font-family: "Helvetica Neue";
            @media screen and (max-width: $bp-mobile){
                font-size: 1rem;
                margin-left: 1rem;
                font-weight: 500;
            }

        }
        .btn-arrow{
            opacity: 1;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            background: #eeecec;
            margin: 0 0.2rem;
            position: absolute;
            z-index: 3;
            display: none;
            @media screen and (max-width: $bp-mobile){
                display: block;
            }
        }
        .arrow-left{
            left: -2px;
            bottom: 35%;
        }
        .arrow-left_disabled{
            opacity: 0;
        }
        .arrow-right{
            right: -2px;
            bottom: 35%;
        }
        .arrow-right_disabled{
            opacity: 0;
        }

    }
    .foods-category-main::-webkit-scrollbar{
        display: none;
    }
    .foods-category-main{
        padding-top: 30px;
        overflow-x:scroll ;
        display: grid;
        grid-template-columns: repeat(8,1fr);
        grid-gap: 30px;
        grid-row-gap: 30px;
        @media screen and (max-width: $bp-mobile){
            padding:16px 7px 0;
            grid-row-gap: 16px;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
        }
    }
    .food-category-wrapper{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        span{
            font-size: 16px;
            font-weight: 600;
            @media screen and (max-width: $bp-mobile){
                font-size: 10px;
            }
        }
        
    }
    .img-wrap{
        height: 60px;
        width: 60px;
        border-radius:50% ;
        overflow: hidden;
        margin-bottom: 10px;
        box-shadow: 0 5px 10px 0 darkgray;
        @media screen and (max-width: $bp-mobile) {
            height: 40px;
            width: 40px;
        }
        img{
            display: block;
            height: 100%;
            width: 100%;
        }
    }

}

</style>
