<template>
    <div class="foodslide">
        <div class="foodslide-wrapper">
            <div class="foodslide-header">
                <h1>Cuisines</h1>
            </div>

            <div v-if="!requested" class="loader">
                <div v-for="i in 7" :key="i" class="mr-2">
                    <v-skeleton-loader
                    height="130"
                    width="160"
                    type="image"
                    ></v-skeleton-loader>
                </div>
            </div>

            <div v-if="requested">
            <div v-if="!isError" class="foodslide-main" @touchstart="touch" @touchend="stopTouch">
                <div icon class="btn-arrow arrow-desktop" @click="swipeLeft">
                    <v-icon size="55" color="white">
                        {{icons.mdiChevronLeft}}
                    </v-icon>
                </div>
                <v-btn icon class="btn-arrow arrow-mobile" style="left:-10px" @click="swipeLeft" elevation="5">
                    <v-icon size="30" color="#ee2d24" >
                        {{icons.mdiChevronLeft}}
                    </v-icon>
                </v-btn>

                <div class="food-slider" ref="scrollTab">
                    <div v-for="food in foodsCategory" :key="food.id" class="food-container" @click="handleSwipe">
                        <div class="food-box" @click="goToCuisine(food)">
                            <img :src="food.thumbnail" alt="">
                            <div class="food-title">{{ food.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="btn-arrow arrow-desktop" @click="swipeRight">
                    <v-icon size="60" color="white">
                        {{icons.mdiChevronRight}}
                    </v-icon>
                </div>
                    <v-btn 
                    icon class="btn-arrow arrow-mobile" style="right:-10px" @click="swipeRight" elevation="5">
                        <v-icon size="30" color="#ee2d24">
                            {{icons.mdiChevronRight}}
                        </v-icon>
                    </v-btn>
            </div>
            <div v-else>
                <div style="height:6rem;text-align:start;padding:1rem 0;font-size:1.1rem;color:gray">No Item Available</div>
            </div>
            </div>
            

        </div>
    </div>
</template>
<script>
import { mdiChevronLeft,mdiChevronRight } from '@mdi/js';
import { getStoreCategory} from '@/components/api/api'
import { mainMixin } from '@/mixins'

export default {
name: 'FoodSlide',
data() {
    return{
        isVisibleButtonLeft: false,
        isVisibleButtonRight: true,
        isError:false,
        requested:false,
        icons:{
            mdiChevronLeft,
            mdiChevronRight
        },
        foodsCategory : []
    }
},
mixins: [mainMixin],
created() {
    this.getData();
},
methods: {
    getData(){
        getStoreCategory()
        .then(res =>{
            console.log('store category =>', res.data)
                this.foodsCategory = res.data
                this.requested = true
        }).catch(error => {
            console.log('Error! :',error)
            this.isError= true
            this.requested = true
        })
    },
    goToCuisine(food){
        let query = food.name.toLowerCase().trim()
        let url= {
            path: `/cuisines`
        }
        this.$router.push({path:url.path, query: {  q: query }})
    },
    stopTouch(){
        setTimeout(()=>{
            this.handleSwipe()
        },200)
    },
    touch(){
        this.handleSwipe()
    },
    handleSwipe(){
        if(this.$refs.scrollTab == undefined) return
        
        if(this.$refs.scrollTab.scrollLeft > 0){
            this.isVisibleButtonLeft = true
            if(this.$refs.scrollTab.scrollLeft + this.$refs.scrollTab.clientWidth < this.$refs.scrollTab.scrollWidth -20){
                this.isVisibleButtonRight = true
            }else{
                this.isVisibleButtonRight = false
            }
        }else{
            this.isVisibleButtonLeft= false
            this.isVisibleButtonRight = true
        }
    },
    swipeLeft() {
        this.isDisabled = true
        const content = this.$refs.scrollTab
        this.scrollTo(content, -100, 400);
        this.isDisabled= false
    },
    swipeRight() {
        this.handleSwipe()
        const content = this.$refs.scrollTab
        this.scrollTo(content, 100, 400);
    },
}
};
</script>
<style lang="scss">
@import "../css/global.scss";
@import "../css/common.scss";

.foodslide{
    padding: 3rem 0;
    background: #f3f7f870;
    @media screen and (max-width: $bp-mobile){
        padding: 1.5rem 0 1rem;
    }
    .foodslide-wrapper{
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 1rem;
        h2{
            display: block;
        }
    }
    .foodslide-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1{
            font-size:30px;
            color: rgba(0, 0, 0, 0.87);
            margin-left: 2.5rem;
            font-family: "Helvetica Neue";
            @media screen and (max-width: $bp-mobile){
                font-size:16px;
                margin-left: 1rem;
                font-weight: 500;
            }
        }
        .foodslide-title_seeAll{
            color: $gray1;
            font-size: 16px;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        span{
            font-size: 16px;
            font-weight: 500;
            @media screen and (max-width: $bp-mobile){
                font-size:14px;
                font-weight: 400;
            }
        }
    }
    .loader::-webkit-scrollbar { display: none; }
    .loader{
        display: flex;
        overflow-x: scroll;
        margin-left: 2rem;
        margin-top: 1rem;
        scrollbar-width: none !important;
        @media screen and (max-width: $bp-mobile){
            margin-left: 0;
        }
    }
    .foodslide-main{
        display: flex;
        align-items: center;
        position: relative;
    }
    .btn-arrow{
        opacity: 0.6;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
    }
    .arrow-desktop{
        background: $bgColor2;
        height: 60px;
        border-radius: 5px;
        width: 40px;
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
    }
    .arrow-mobile{
        display: none;
        @media screen and (max-width: $bp-mobile){
            display: flex;
            background: #fff;
            margin: 0 0.2rem;
            position: absolute;
            opacity: 1;
            z-index: 3;
        }
    }
    .btn-arrow:hover{
        opacity: 1;
    }
    .food-slider{
        display: flex;
        overflow-x: scroll ;
        scrollbar-width: none;
        padding: 10px 0;
        margin: 0;
    }
    .food-slider::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
    .food-container:first-child{
        margin: 0 10px 0 2px;
        @media screen and (max-width: $bp-mobile){
            margin: 0 3px;
        }
    }
    .food-container{
        width: fit-content;
        margin: 0 10px;
        @media screen and (max-width: $bp-mobile){
            margin: 0 3px;
        }
    }
    .food-box::after{
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* inset: 0;  // not working in safari */
        z-index: 0;
        background:rgba(169, 169, 169, 0.3);
        transition: 0.3s ease-out;
    }
    .food-box:hover:after{
        background: rgba(163, 162, 162, 0.5);
    }
    .food-box{
        cursor: pointer;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 160px;
        width: 240px;
        img{
            display: block;
            /* height: 160px; */
            padding: 0;
            margin: 0;
            max-width: 100%;
            height: 100%;
        }
        @media screen and (max-width: $bp-mobile){
            height: 110px;
            width: 165px;
        }
    }
    .food-box:hover{
        box-shadow: 0 0 0 3px green;
    }
    .food-title{
        width: fit-content;
        height: 20px;
        position: absolute;
        bottom: 50%;
        top: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        color: white;
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        
    }
    
    

}
</style>