<template>
    <div class="search-section">
        <div class="main">
            <h1 ref="searchRef">Search</h1>
            <van-overlay z-index="10" :show="overlay" @click="overlay = false" />
            <div :class="{ 'fixed-top':isScrolledTop}">
                <SearchBar @activateOverlay="setOverlay" :overlay="overlay" />
            </div>
        </div>
    </div>
</template>
<script>
import SearchBar from './SearchBar.vue'

export default {
name: "SearchSection",
components: {
    SearchBar
},
created () {
    window.addEventListener('scroll', this.throttle(this.handleScroll, 20));
},
beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
},
data (){
    return {
        overlay:false,
        isScrolledTop:false,
    }
},
methods: {
    setOverlay(){
        this.overlay= true;
    },
    throttle(callback,limit){
        let wait = false;
        return function(){
            if(!wait){
            callback.call();
            wait =true;
            setTimeout(function (){
                wait= false;
            },limit)
            }
        }
    },
    handleScroll(){
        const element =this.$refs.searchRef && this.$refs.searchRef.offsetTop;
        if( element <= window.pageYOffset){
            this.isScrolledTop = true
        }else{
            this.isScrolledTop = false
        }
    },  
}

}

</script>
<style lang="scss" scoped>
@import "./css/global.scss";
@import "./css/common.scss";

.search-section{
    display: none;
    background: #fff;    
    box-shadow: 0 4px 4px 0 rgb(224, 224, 224);
    @media screen and (max-width: $bp-mobile){
        display: block;
    }
}
.main{
    padding: 10px 14px 20px;
    max-height: 112px;
}
h1{
    font-size: 16px;
    text-align: left;
    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Helvetica Neue";
    font-weight: 500;
}
.fixed-top{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $bgColor2;
    z-index: 100;
    padding: 10px;
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0);
}

</style>