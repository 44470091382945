<template>
  <div class="merchants-map">
    <h1>OUR MERCHANTS</h1>

    <div class="map-leaflet">
      <l-map ref="myMap" style="height: 350px;z-index:1" 
      :zoom="zoom" 
      :center="center" 
      :options="{
        scrollWheelZoom: false,
      }" >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker :lat-lng="circle.center" ></l-marker>
      
      <l-feature-group>
        <l-marker :lat-lng="marker[1]" v-for="marker in markers" :key="marker.id">
          <l-tooltip>
            <div style="max-width:130px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{marker[0]}}</div>
          </l-tooltip>
        </l-marker>
      </l-feature-group>
      <!-- <l-circle
            :lat-lng="circle.center"
            :radius="circle.radius"
            :color="circle.color"
          /> -->
      </l-map>
    </div>

  </div>
</template>

<script>
import {LMap, LTileLayer, LCircle,LMarker,LFeatureGroup,LTooltip} from 'vue2-leaflet';
import { latLng } from "leaflet";
import { getStoreCategory } from '@/components/api/api.js'

export default {
  name: "MerchantsMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LFeatureGroup,
    LTooltip
    // LCircle
  },
  data: () => ({
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 11,
    center: [3.139003,101.686855],
    circle: {
      center: [3.139003,101.686855],
      radius: 400,
      color: 'red'
    },
    markers:[],
  }),
  created(){
    this.getData()
  },
  methods: {
    getData(){
        getStoreCategory()
            .then(res => {
                const indexpage = res.data
                indexpage.map(data =>{
                  data.stores.forEach(element => {
                    let m =[]
                    let x = element.lat == 0 ? "": element.lat ;
                    let y = element.lng == 0 ? "": element.lng ;
                    let coordinate = latLng(x,y)
                    m.push(element.name,coordinate)
                    this.markers.push(m)
                  });
                })
            }).catch(error => {
                console.log('error :', error)
            })
    },
  }

};
</script>


<style lang="scss" scoped>
@import "../components/common/css/global.scss";

.merchants-map {
  position: relative;
  min-height: 600px;
  text-align: center;
  padding: 60px 40px;
  background: #f3f7f870;
  & h1 {
    margin-bottom: 25px;
    font-family: "Helvetica Neue";
  }

  & .map-leaflet{
    max-width: 1300px;
    margin: 0 auto;
  }

  @media (max-width: $bp-mobile) {
    padding: 30px 14px 80px;
    min-height: 500px;

    & h1 {
      font-size: 25px;
    }
  }
}
</style>