<template>
  <div class="wrapper" @click="close">
    <div class="login">
      <h1>Sign In</h1>
      <v-text-field
        label="Username"
        persistent-hint
        filled
        background-color="rgba(0,0,0,0.08)"
      >
      </v-text-field>
      <v-text-field
        label="Password"
        type="password"
        persistent-hint
        filled
        background-color="rgba(0,0,0,0.08)"
      >
      </v-text-field>
      <div class="login__buttons">
        <button class="btn-hero btn-red">Sign In</button>
      </div>
      <a class="login__forgotPassword">Forgot password</a>
      <p>Don't have an account? <a>Sign up</a></p>
      <div class="login__close">
        <v-icon medium @click="close">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  methods: {
    close(e) {
      if (e.target === e.currentTarget) this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./css/button.scss";
@import "./css/global.scss";
@import "./css/common.scss";


.wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: absolute;
  top: 0;
}
.login {
  position: relative;
  background-color: $white;
  padding: 30px;
  z-index: 1200;
  width: 50%;
  max-width: 500px;
  min-width: 300px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  & h1 {
    margin-bottom: 20px;
  }

  & a {
    color: $red1;
    font-weight: 600;
    font-size: 14px;
  }

  & p {
    font-size: 14px;
  }

  & .v-text-field ::v-deep {
    & .v-input__slot::before {
      border-style: none !important;
    }
    & .v-input__slot {
      border-radius: 0px;
    }
  }
}

.login__buttons {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
}

.login__forgotPassword {
  margin-bottom: 5px;
  color: black;
  display: inline-block;
}

.login__close {
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 2000;
  font-size: 25px;
}

.btn-hero {
  text-align: center;
  height: auto;
  border-radius: 0;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 1;
  margin: 0;
  max-width: 100vw;
}
</style>