<template>
    <div class="search">
            <div class="search-main" >
                  <div class="search-input-main" :class="{'search-input-main__active': overlay}" @click="handleClick">
                    <img :src="SearchLogo" alt="" :style="[hideLogo ? {'display':'none'} : '', overlay ? {'display':'none'} : '']" >
                    <v-icon size="22" color="#ee2d24">
                        {{ icons.mdiMagnify }}
                    </v-icon>
                    <input id="input-search" ref="input" @keyup.enter="handleSubmit" placeholder="Search for restaurants, cuisine type, food type, anything" :value="searchQuery" @input="e => searchQuery = e.target.value" v-on:input="inputSearch()" />
                    <v-btn icon small v-if="searchQuery" @click="clearInput">
                      <v-icon size="20">{{icons.mdiClose}}</v-icon>
                    </v-btn>

                    <div v-if="searchQuery.length>0" class="search-results" :class="{'search-results_active-overlay': overlay}" tabindex="0">

                        <div class="search-results-main" v-if="isLoading">
                          <div style="padding:.2rem 0.5rem;font-size:1rem">Loading..</div>
                        </div>

                        <div class="search-results-main" v-if="!isLoading && hits.length>0">
                          <div class="result-search"  v-if="Object.values(categories).length>0 || restaurants.length>0 || products.length>0 || addressess.length>0">

                            <div v-if="tags.length>0" style="border-bottom:1px solid #e2e2e2;width:100%;padding:0.6rem 0;margin:0 0.4rem">
                              <h5 style="color:rgba(0, 0, 0, 0.7);">Tags</h5>

                              <div>
                                <v-chip class="ma-1" color="primary" v-for="tag in tags" :key="tag"
                                style="font-size:12px"
                                small
                                @click="handleKeySearch(tag)"
                                >
                                  <v-icon left x-small>
                                    mdi-label
                                  </v-icon>
                                  #{{tag}}
                                </v-chip>
                              </div>
                            </div>

                            <div v-if="Object.values(this.categories).length>0" style="border-bottom:1px solid #e2e2e2;width:100%;padding:0.6rem 0;margin:0 0.4rem">
                              <h5 style="color:rgba(0, 0, 0, 0.7);">Categories</h5>
                              <div v-for="category in Object.values(categories)" @click="handleKeySearch(Object.keys(category)[0])" :key="category.id" style="display:flex;align-items:center;width:100%;margin-top:.5rem" >
                              <div>
                                <v-icon size="22" class="icon-clock" color="#b59bc4" style="margin-right:0.6rem">{{icons.mdiMagnify}}</v-icon>
                              </div>
                              <div style="font-size:1rem;color:rgba(0, 0, 0, 0.5);">{{Object.keys(category)[0]}} <span style="font-size:1rem;color:darkgray">({{Object.values(category)[0]}})</span></div>
                            </div>
                            </div>

                            <div v-if="restaurants.length>0" style="border-bottom:1px solid #e2e2e2;width:100%;padding:0.6rem 0;margin:0 0.4rem">
                              <h5 style="color:rgba(0, 0, 0, 0.7);">Restaurants</h5>
                              <div v-for="restaurant in restaurants.slice(0,4)" :key="restaurant.id" @click="handleKeySearch(restaurant.name)"  style="display:flex;align-items:center;width:100%;margin-top:.5rem;padding-left:.7rem" >
                              <div style="font-size:1rem;color:rgba(0, 0, 0, 0.5);display:flex">
                                <div style="margin-right:.5rem">
                                  <v-card width="64">
                                    <v-img :src="restaurant.thumbnail" height="64"></v-img>
                                  </v-card>
                                </div>
                                <div>
                                    <span style="color:rgba(0, 0, 0, 0.7)">{{restaurant.name}}</span>
                                    <div style="display:flex">
                                      <div v-for="feature in restaurant.features" :key="feature.id" style="font-size:0.7rem">
                                      {{feature}},
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="restaurants.length>4" @click="handleSubmit" style="font-size:0.9rem;color:#ee2d24;padding:0.2rem 0.8rem;margin-top:0.4rem">
                                View all {{restaurants.length}} restaurants
                              </div>
                            </div>

                            <div v-if="products.length>0" style="border-bottom:1px solid #e2e2e2;width:100%;padding:0.6rem 0 1rem;margin:0 0.4rem">
                              <h5 style="color:rgba(0, 0, 0, 0.7);">Items</h5>
                              <div v-for="product in products.slice(0,5)" :key="product.id" @click="handleKeySearch(product.name)" style="display:flex;align-items:center;margin-top:.5rem;padding-left:0rem;" >
                              <div style="font-size:1rem;color:rgba(0, 0, 0, 0.5);display:flex">
                                <div style="margin-right:.5rem">
                                  <v-card width="64">
                                    <v-img :src="product.thumbnail" height="64"></v-img>
                                  </v-card>
                                </div>
                                <div>
                                  <div style="font-size:.9rem;line-height:1.1rem;color:rgba(0, 0, 0, 0.7)">{{product.name}}</div>
                                  <div style="font-size:9px" class="mt-1">{{product.description}}</div>
                                  <div style="display:flex;flex-wrap:wrap;" class="mt-1">
                                          <v-chip v-for="tag in product.tags" :key="tag"
                                          style="margin-right:2px;margin-bottom:2px;padding:3px;height:.5rem;font-size:7px"
                                          color="#03a9f4"
                                          text-color="white"
                                          x-small
                                        >
                                          {{tag}}
                                        </v-chip>
                                  </div>
                                </div>
                              </div>
                            </div>
                              <div v-if="products.length>5" @click="handleSubmit" style="font-size:0.9rem;color:#ee2d24;padding:0.2rem 0.8rem;margin-top:0.4rem">View all {{products.length}} items</div>
                            </div>

                            <div v-if="addressess.length>0" style="border-bottom:1px solid #e2e2e2;width:100%;padding:0.6rem 0;margin:0 0.4rem">
                              <h5 style="color:rgba(0, 0, 0, 0.7);">Places</h5>
                              <div v-for="item in addressess.slice(0,5)" :key="item.id" @click="handleKeySearch(item.store.address)" style="display:flex;align-items:center;width:100%;margin-top:.5rem;padding-left:.2rem;padding-bottom:0.4rem" >
                              <div style="font-size:1rem;color:rgba(0, 0, 0, 0.5);display:flex">
                                <div style="margin-right:.5rem">
                                  <v-icon>{{icons.mdiMapMarker}}</v-icon>
                                </div>
                                <div>
                                  <span style="font-size:.9rem;color:rgba(0, 0, 0, 0.7)">{{item.store.address}}</span>
                                </div>
                              </div>
                            </div>
                              <div v-if="addressess.length>1" @click="handleSubmit" style="font-size:0.9rem;color:#ee2d24;padding:0.2rem 0.8rem;margin-top:0.4rem">View all restaurants near the places
                              </div>
                            </div>

                          </div>
                          <div v-else style="padding:.2rem .5rem;font-size:1rem" @click="handleSubmit">
                            <i>Click enter to search</i>
                          </div>
                      </div>                      
                      <!-- no result -->
                      <ul class="search-results-main" v-else-if="!isLoading && hits.length==0">
                        <div style="padding:5px;font-size:1rem">No Result</div>
                      </ul>
                    </div>

                    <div class="search-history" :class="{'search-history_active-overlay': overlay}">
                      <div v-if="!searchQuery" style="padding:5px 0">
                      <div v-if="searchHistory.length>0" class="recent-search">
                        <div class="type">recent search</div>
                        <div class="recent-search-results">
                          <div class="result" v-for="recentSearch in searchHistory.slice(0,4)" :key="recentSearch.id">
                            <div class="left-icon" @click="handleKeySearch(recentSearch)">
                              <v-icon size="20" class="icon-clock" color="#b59bc4">{{icons.mdiClockTimeFourOutline}}</v-icon>
                              <div class="text" ref="recentSearchRef">
                                {{recentSearch}}
                              </div>
                            </div>

                            <div class="right-icon">
                              <v-btn icon small color="#e7817c" :value="recentSearch" @click="deleteSearchKey">
                                <v-icon tabindex="0" size="20" class="icon-trash">{{icons.mdiTrashCanOutline}}</v-icon>
                              </v-btn>
                              <v-btn icon small color="#b59bc4" :value="recentSearch" @click="selectSearchKey">
                                <v-icon size="20" class="icon-arrow">{{icons.mdiArrowTopLeft}}</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="trending-search">
                        <div class="type">trending search</div>
                        <div class="trending-search-results">
                          <button class="result" v-for="trendSearch in searchTrending.slice(0,4)" :key="trendSearch.id">
                            <div class="left-icon" @click="handleKeySearch(trendSearch)">
                              <v-icon size="20" class="icon-clock" color="#b59bc4">{{icons.mdiMagnify}}</v-icon>
                              <div class="text" ref="trendSearchRef">
                                {{trendSearch}}
                              </div>
                            </div>

                            <div class="right-icon">
                              <v-btn icon small color="#b59bc4" @click="selectSearchKey" :value="trendSearch">
                                <v-icon size="20" class="icon-arrow">{{icons.mdiArrowTopLeft}}</v-icon>
                              </v-btn>
                            </div>
                          </button>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
            </div>
    </div>


</template>
<script>
import { mdiMapMarker,mdiMagnify,mdiCrosshairsGps,mdiClockTimeFourOutline,mdiTrashCanOutline,mdiArrowTopLeft,mdiClose  } from '@mdi/js';
import algoliasearch from 'algoliasearch/lite';
import SearchLogo from '../../assets/logo2.png'

export default {
    name: "SearchBar",
    props: {
      overlay: {
        type: Boolean
      },
      hideLogo: {
        type: Boolean
      }
    },
    data() {
        return {
          categories:[],
          tags: [],
          cuisines:[],
          restaurants:[],
          dishes:[],
          addressess:[],

          hits: [],
          searchHistory:[],
          searchTrending: [
            "prawn",
            "western",
            "Huen Kee",
            "dim sum"
          ],
          SearchLogo,
          index : null,
          icons: {
              mdiMapMarker,
              mdiMagnify,
              mdiCrosshairsGps,
              mdiClockTimeFourOutline,
              mdiTrashCanOutline,
              mdiArrowTopLeft,
              mdiClose
          },
          isScrolledTop:false,
          results: [],
          searchQuery: "",
          showSearch: false,
          isLoading: false,
          numhitsPerPage:20,
        }
    },
  created () {
    this.index = this.$searchClient.initIndex(this.$clientIndex)
    if(localStorage.getItem("search-history") != undefined){
      this.searchHistory = JSON.parse(localStorage.getItem("search-history"))
    }
  },
  methods: {
    deleteSearchKey(e){
      let target = e.currentTarget.value
      let searchArray= JSON.parse(localStorage.getItem("search-history"))
      let filter = searchArray.filter(item => item != target)
      localStorage.setItem("search-history",JSON.stringify(filter));
      this.searchHistory = filter
    },
    selectSearchKey(e){
      let value= e.currentTarget.value
      this.searchQuery = value;
      this.inputSearch()
    },
    handleSubmit(){
      let x = [];
      let result = this.searchQuery.toLowerCase().replace(/\s+/g,' ').trim()

      if(localStorage.getItem("search-history") != undefined){
        x = JSON.parse(localStorage.getItem("search-history"));
      }
      if(x.indexOf(result) === -1){
        x.unshift(result)
        this.searchHistory.unshift(result)
        localStorage.setItem("search-history",JSON.stringify(x));
      }
      let path = this.$route.path
      let param = this.$route.query.q

      if(path !== '/search' || param !== result){
        this.$router.push({path:'/search', query:{q: result}})
      }
      setTimeout(()=>{
        this.$emit('deactivateOverlay')
      },100)

      this.clearInput()
    },
    clearInput(){
      this.searchQuery = "";
    },
    handleClick(e){
      if(this.$refs.input){
        this.$refs.input.focus()
      }
      this.$emit('activateOverlay')
    },
    handleKeySearch(result){
      let strReplace= result.replaceAll('<em>','').replaceAll('</em>','').trim()
      this.searchQuery = strReplace;
      this.inputSearch()
      this.handleSubmit()
    },  
    async inputSearch(){
      if(this.searchQuery != ""){
        this.isLoading = true

        try {
          const result = await this.index.search(this.searchQuery,{
            // page: 1,
            hitsPerPage: this.numhitsPerPage
        });
          if(result){
            this.isLoading =false
          }

          let hits = result.hits;
          console.log('hits =>',result)
          this.hits = hits
          let search = []

          let categories =[]
          let tags = []
          let restaurants = []
          let products = []
          let addressess =[]

          hits.forEach(hit =>{
            //products name
            if(hit._highlightResult.name != undefined && hit._highlightResult.name.fullyHighlighted != undefined){
                let name_restaurant = hit._highlightResult.name.value.replaceAll('<em>','').replaceAll('</em>','').trim()
                if(hit.name != name_restaurant) return
                  products.push(hit)
            }

            // addresses
            if(hit._highlightResult.store.address != undefined && hit._highlightResult.store.address.fullyHighlighted != undefined){
                let address = hit._highlightResult.store.address.value.replaceAll('<em>','').replaceAll('</em>','').trim()
                if(hit.store.address.toLowerCase().trim().replace(/[/]/g,'') != address.toLowerCase().trim()) return
                  addressess.push(hit)
            }
            //tags
            if(hit._highlightResult.tags != undefined){
                hit._highlightResult.tags.forEach(tag =>{
                if(tag.fullyHighlighted == undefined) return
                  let tag_value = tag.value.replaceAll('<em>','').replaceAll('</em>','').trim()
                  tags.push(tag_value)
                  products.push(hit)
                })
            }

            //category
            if(hit._highlightResult.categories != undefined){
                hit._highlightResult.categories.forEach(category =>{
                if(category.fullyHighlighted == undefined) return
                  let type = {}
                  type.categories = hit.categories
                  type.storeid = hit.store.id
                  categories.push(hit.categories)
                })
            }

            if(hit._highlightResult.store.categories != undefined){
                hit._highlightResult.store.categories.forEach(category =>{
                if(category.fullyHighlighted == undefined) return
                  categories.push(hit.store.categories) 
                })
            }
          })

          this.restaurants= restaurants

          const uniqueTags = tags.reduce((acc, current) => {
            const x = acc.find(item => item == current);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          this.tags =uniqueTags

          const uniqueDishes = products.reduce((acc, current) => {
            const x = acc.find(item => item.id == current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          this.products = uniqueDishes

          const uniqueAddr = addressess.reduce((acc, current) => {
            const x = acc.find(item => item.store.address === current.store.address);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          this.addressess = uniqueAddr

          this.categories= categories.reduce((acc, curr) => {
            acc[curr] ??= {[curr]: 0};
            acc[curr][curr]++;
            return acc;
          }, {});

        } catch (error) {
            this.isLoading =false
            console.log('search error',error)
        }
    }
    },
  },

}
</script>

<style lang='scss' scoped>
@import "./css/global.scss";
@import "./css/animation.scss";
@import "./css/common.scss";

.search{
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.search-wrapper{
  margin: 0;
}
.search-main{
  background: transparent; 

  .van-overlay{
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}
.search-input-main{
  background: #fff;
  position: relative;
  font-size: 20px;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #c5c5c5;
  margin: 0;
  max-width: 650px;
  padding: 5px 10px;

  #input-search::placeholder{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img{
    height: 26px;
    margin-right: 0.8rem;
  }

  input{
      font-size: 14px;
      outline: none;
      padding: 5px;
      flex: 1;
      width: 100%;
  }

  input::placeholder{
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
  }

  .search-input__gps-icon{
      background-color: rgb(241, 241, 241);
      padding: 2px;
      border-radius: 50%;
      margin-left: 5px;
  }
}
.search-input-main__active{
  z-index: 12;
  position: relative;
  box-shadow: inset 0 0 1px 3px $yellow0;
}
.search-button{
    margin-left: 20px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    & span{
        font-size: 22px;
        margin: 0 10px;
    }
    button{
        background: $bgColor2;
        font-size: 14px;
        height: 41px;
        width:160px;
        color: $yellow0;
        font-weight: 600;
        border-radius: 100px;
    }
}
.search-results{
  display: none;
}
.search-results_active-overlay{
    display: block;
    background: $white;
    position: absolute;
    top:42px;
    left: 0;
    right: 0;
    z-index: 210;
    margin: 0;
    overflow-x: hidden;
    text-align: left;
    border-radius: 3px;
}
.search-results-main{
  padding: 5px 2px !important;
  margin: 0 !important;
  max-height: 350px;
}
.result-search{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px 0 4px;
}
.search-text{
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: $gray1;
}
.search-results__status {
  position: absolute;
  top: -20px;
  left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: $red1;
  text-align: start;
}
.search-history{
  display: none;
}
.search-history_active-overlay{
    display: block;
    background: $white;
    position: absolute;
    top:42px;
    left: 0;
    right: 0;
    max-height: 400px;
    z-index: 210;
    margin: 0;
    overflow-x: hidden;
    text-align: left;
    border-radius: 3px;
}
.result{
  display: flex !important;
  align-items: center !important;
  padding: 5px 10px !important;
  height: 40px !important;
  width: 100% !important;
  cursor: pointer;
  background: transparent !important;
  box-shadow: none !important;
}
.result:hover{
  background: #7035921f;
}
.left-icon{
  flex: 1;
  display: flex;
  align-items: center;
  width: 80%;
}
.right-icon{
  display: flex;
  width: 100%;
  max-width: 50px;
  justify-content: flex-end;
}

.icon-clock{
  margin-right: 15px;
}
.text{
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:fit-content;
  padding-right: 10px;
  text-transform: lowercase !important;
}
.type {
    font-size: 14px;
    padding: 5px 15px;
    color: darkgray;
  }

::v-deep .em-tag > em{
    background: wheat !important;
}
</style>