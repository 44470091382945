import axios from 'axios'

const get = (url, params) =>{
    return new Promise((resolve, reject) => {
        axios.get(url, {
        params: params
        }).then(res => {
        resolve(res.data)
        }).catch(err => {
        reject(err.data)
        })
    })
}

//https://sb-api.fastorder.my/fomy/v2/product/sales/trending/list?
export const getFoodsTrending= (apiParams) => get("https://sb-api.fastorder.my/fomy/v2/product/sales/trending/list", apiParams)

// https://sb-api.fastorder.my/fomy/v2/store/list/tag
export const getStoreTag = (apiParams) => get("https://sb-api.fastorder.my/fomy/v2/store/list/tag", apiParams)

// https://sb-api.fastorder.my/fomy/v2/store/list/category
//https://api.justkliq.com/jmjk/v2/store/list/category

export const getStoreCategory = (apiParams) => get("https://sb-api.fastorder.my/fomy/v2/store/list/category", apiParams)

// https://sb-api.fastorder.my/fomy/v2/store/list
export const getStoreList = (apiParams) => get("https://sb-api.fastorder.my/fomy/v2/store/list", apiParams)

// https://sb-api.fastorder.my/fomy/v2/product/promotion/list
export const getPromoList = (apiParams) => get("https://sb-api.fastorder.my/fomy/v2/product/promotion/list", apiParams)






