import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Vant from 'vant';
import 'vant/lib/index.css';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'swiper/css/swiper.css'
import InstantSearch from 'vue-instantsearch';
import { Icon } from 'leaflet';
import algoliasearch from 'algoliasearch/lite';
import CrispChat from '@dansmaculotte/vue-crisp-chat'
import VueObserveVisibility from 'vue-observe-visibility'


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// For font awesome icons
library.add(faFacebook, faInstagram);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// observe visibility
Vue.use(VueObserveVisibility)

// Crisp chat
Vue.use(CrispChat, {
  websiteId: 'fb8c0fad-4d91-4c0a-ab64-83539ba22482',
  // disabled: true,
  hideOnLoad: false
})

//leaflet
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.prototype.$clientIndex = 'FO';
Vue.prototype.$searchClient = algoliasearch('CZKWG0TEKX','f81cc1400a123e2ce8dc4f30fd8998c7'),

Vue.config.productionTip = false;

Vue.use(InstantSearch);

Vue.use(VueAwesomeSwiper)
Vue.use(Vant);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
