<template>
    <div class="promo" v-if="requested">

        <div class="promo-wrapper" v-for="promo in promolist" :key="promo.id">
            <div class="promo-header">
                <h1>{{ fnPromoTag(promo.tag) }} Promotion</h1>
            </div>

            <!-- <div v-if="!requested" class="loader">
                <v-skeleton-loader
                height="130"
                max-width="170"
                type="image"
                ></v-skeleton-loader>
            </div> -->

                <swiper class="swiper promo-restaurant" :options="swiperOption">
                    <swiper-slide v-for="product in promo.product" :key="product.id">
                        <div class="promo-card" @click="clickFood(product)">
                            <div class="promo-img">
                                <img :src="product.thumb" alt="">
                                <div class="promo-tag">Promo</div>
                            </div>

                            <div class="promo-info-card">
                                <div class="promo-product-info">
                                    <div class="product-name">{{product.name}}</div>
                                    
                                    <div class="product-price">
                                        <p>from</p>
                                        <div class="marketprice"> RM {{product.marketprice}}</div>
                                        <div class="productprice">RM {{product.productPrice}}</div>
                                    </div>

                                </div>
                                <h6 class="store-name">{{product.store.name}}</h6>
                                <!-- <div class="discount">
                                    <v-icon size="22" color="#703592" style="transform:scaleX(-1)">
                                        {{icons.mdiTagOutline}}
                                    </v-icon>
                                    {{store.promo}}                                
                                </div> -->
                            </div>
                        </div>
                    </swiper-slide>
                    
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>            

        </div>
    </div>
</template>
<script>
import { mdiChevronLeft,mdiChevronRight } from '@mdi/js';
import { getStoreCategory, getPromoList} from '@/components/api/api'
import { mainMixin } from '@/mixins'
import { mdiTagOutline } from '@mdi/js';

export default {
    name: 'FoodSlide',
    data() {
        return{
            icons:{
                mdiTagOutline
            },
            requested:false,
						promolist: [],
            stores:[
                {
                    name:"Store 1",
                    thumbnail: "https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpg",
                    promo: "Spend RM50, Save RM3 Lorem ipsum, dolor sit amet consectetur adipisicing elit."
                },
                {
                    name:"Store 2",
                    thumbnail: "https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpgs",
                    promo: "Spend RM50, Save RM3 Lorem ipsum, dolor sit amet consectetur adipisicing elit."
                },
                {
                    name:"Store 3",
                    thumbnail: "https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpgs",
                    promo: "Spend RM50, Save RM3 Lorem ipsum, dolor sit amet consectetur adipisicing elit."
                },
                {
                    name:"Store 4",
                    thumbnail: "https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpgs",
                    promo: "Spend RM50, Save RM3 Lorem ipsum, dolor sit amet consectetur adipisicing elit."
                },
            ],
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints:{
                    1040: {
                        slidesPerView: 4,
                        spaceBetween: 20
                    },
                    800: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    310: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    200: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                }
            }
        }
    },
    mixins: [mainMixin],
    created() {
			this.getData()
    },
    methods: {
    clickFood(product){
				console.log(product)
        const itemId =product.id
        const storeId = product.store.id
        const origin =  "https://merchant.fastorder.my"
        const pathname= "/v202105/"
        const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&itemid=${itemId}`
        const url = origin+pathname+hash
        window.location= url
    },
		getData(){
			getPromoList()
				.then(res => {
					console.log('promo list', res.data)
					this.promolist = res.data
					if(this.promolist.length> 0)return this.requested= true
				}).catch(error => {
						console.log('error: ',error)
				})
		},
		fnPromoTag(tag){
			const splitTag= tag.toString().toLowerCase().split("-promo")[0] || '';
			const splitTagArray = splitTag.split('_')

			let subTags= splitTagArray.map(tag =>{
				let x = tag.split('')
				let y= []

				for(let i=0; i<x.length; i++){
					let z = ''
					if(i==0){
						z =x[i].toUpperCase()
					}else{
						z= x[i]
					}
					y.push(z)
				}
				return y.join('')
			})
			let joinTag = subTags.join(" ")
			return joinTag;	
		}
  }
};
</script>
<style lang="scss" scoped>
@import "../css/global.scss";
@import "../css/common.scss";
@import url('https://fonts.googleapis.com/css2?family=Tinos:ital@1&display=swap');


.promo::before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.2;

    background-image: url("https://image.freepik.com/free-photo/christmas-social-media-banner-background-with-design-space_53876-145490.jpg");
    background-position: left;
    background-size: cover;
}
.promo{
	margin-top: 2rem;
    padding: 2rem 0 4rem;
    background: #ffffff; 
    position: relative;
    @media screen and (max-width: $bp-mobile){
        padding: 1.5rem 0 3rem;
    }
    .promo-wrapper{
        position: relative;
        max-width: 1300px;
        margin: 0 auto;
        padding: 1rem;
        h2{
            display: block;
        }
    }

    .promo-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        position: relative;
        width: fit-content;
        h1{
            display: flex;
            font-size:40px;
            color: #804f02;
            margin-left: 2.5rem;
            font-family: 'Tinos', serif;

            @media screen and (max-width: $bp-mobile){
                font-size:26px;
                margin-left: 1rem;
            }
        }
    }

    .promo-restaurant{
        margin:  0 2.4rem;
        @media screen and (max-width: $bp-mobile){
            margin: 0;
        }
        .promo-card{
            padding: 0;
            max-width: 288px;
            width: 100%;
            .promo-img{
                position: relative;
                overflow: hidden;
                width: 100%;
                border-radius: 8px;
                height: 160px;
                background: rgb(255, 255, 255);
                box-shadow: inset 0 0 1px #a1a1a1;;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 600px){
                    height: 140px;
                }
                @media screen and (max-width: 400px){
                    height: 120px;
                }

                img{
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    
                }
                .promo-tag{
                    position: absolute;
                    right: 0px;
                    bottom: 20px;
                    font-size: 12px;
                    background: $red3;
                    color: #fff;
                    padding: 2px 6px;
                    border-radius: 6px 0 0 2px;
                    font-weight: 600;
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                }
            }

            .promo-info-card{
                margin-top: 12px;
                padding: 0 4px;
                text-align: start;
								.promo-product-info{
									padding: 4px 0;
									.product-name{
										/* line-height: 18px;
										max-height: 36px;
										overflow: hidden; */
										display: -webkit-box;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
										overflow: hidden;
										text-overflow: ellipsis;
										font-size: 15px;
									}
									.product-price{
										display: flex;
										padding: 10px 0 0;
										p{
											margin: 0;
											font-size: 14px;
											@media screen and (max-width: $bp-mobile){
													font-size:12px;
											}
										}
										.marketprice{
											font-weight: bold;
											margin-left:4px ;
											font-size: 14px;
											@media screen and (max-width: $bp-mobile){
													font-size:12px;
											}
										}
										.productprice{
											margin-left: 4px;
											text-decoration: line-through;
											color: #ee2d24;
											font-size: 12px;
											@media screen and (max-width: $bp-mobile){
													font-size:11px;
											}
										}
									}
								}
								
                .store-name{
										font-size: 15px;
                    margin-bottom: 12px;
                    color: rgb(85, 85, 85);
										font-weight: 500;
										display: -webkit-box;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
										overflow: hidden;
										text-overflow: ellipsis;
                    @media screen and (max-width: $bp-mobile){
                        font-size:13px;
                    }
                }
                .discount{
                    font-size: 15px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    @media screen and (max-width: $bp-mobile){
                        font-size: 14px;
                    }

                }
            }
        }
        .swiper-button-disabled {
            opacity: 0 !important ;
        }
        .swiper-button-next,
        .swiper-button-prev{
            background: $bgColor2;
            padding: 10px 3px !important;
            color:white;
            height: 60px;
            border-radius: 5px;
            width: 40px;
            top: 50%;
            @media screen and (max-width: 600px){
                height: 40px;
                width: 25px;
            }
        }
        .swiper-button-prev{
            left: 0px;
        
        }
        .swiper-button-next{
            right: 0;
        }
        .swiper-button-prev:after,
        .swiper-button-next:after{
            font-size:26px;
            font-weight: 600;
            @media screen and (max-width: $bp-mobile){
                font-size:16px;
                font-weight: 600;
            }

        }
        

    }

}
</style>