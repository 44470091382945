import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../view/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../view/About.vue')
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('../view/Accounts.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../view/Search.vue')
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: () => import('../view/Restaurants/Category.vue')
  },
  {
    path: '/explore/:id',
    name: 'Explore',
    component: () => import('../view/Restaurants/Explore.vue')
  },
  {
    path: '/cuisines',
    name: 'CuisineItems',
    component: () => import('../view/Restaurants/CuisineItems.vue')
  },
  {
    path: '/trending',
    name: 'Trending this week',
    component: () => import('../view/TrendingWeek.vue')
  },
  {
    path: '/merchants',
    name: 'New merchants',
    component: () => import('../view/Merchants.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../components/layouts/Account.vue')
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../components/layouts/Favorites.vue')
  },
  {
    path: '/notification',
    name: 'Notifications',
    component: () => import('../components/layouts/Notifications.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../components/layouts/Orders.vue')
  }
  
]

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
