<template>
  <div id="home">
    <Loading  v-if="!requested" />
    <div v-else style="position:relative">
      <TopNavbar :isMobile="isMobile" :scrollActive="scrollActive" />
      <Banner :isMobile="isMobile" :scrollActive="scrollActive" />
      <SearchSection />
      <FoodsPreview />
      <MapMerchants />
      <ContactUs />
      <Footer />
      <NavFooter :isHome="isHome" :scrollActive="scrollActive"/>
    </div>
    
    
  </div>
</template>

<script>
import TopNavbar from "@/components/common/TopNavbar.vue";
import Footer from "@/components/common/Footer.vue";
import Banner from "@/components/common/Banner.vue";
import MapMerchants from "@/view/MapMerchants.vue";
import FoodsPreview from "./FoodsPreview.vue";
import NavFooter from "@/components/common/NavFooter.vue"
import ContactUs from "./ContactUs.vue";
import Loading from "./Loading.vue";
import SearchSection from "../components/common/SearchSection.vue"
import { getStoreTag, getStoreCategory } from '@/components/api/api.js'

export default {
  name: "Home",
  components: {
    NavFooter,
    TopNavbar,
    Banner,
    MapMerchants,
    Footer,
    FoodsPreview,
    ContactUs,
    Loading,
    SearchSection,
  },
  data() {
    return {
      isHome: true,
      scrollActive:false,
      requested: false,
      windowWidth: window.innerWidth,
      showBottomMenu: false,
    }
  },
  mounted(){
  
  },
  created(){
    console.log(process.env)
    this.getData()
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    isMobile() {
      return this.windowWidth <=640
    }
  },
  methods: {
    handleScroll(){
      if (window.scrollY > 150) {
        this.scrollActive = true;
      } else {
        this.scrollActive = false;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    getData(){
      getStoreCategory()
      .then(res =>{
        this.requested = true
      }).catch(error => {
        console.log('Error! :',error)
      })
    },
  }
};
</script>
<style lang="scss">
#home {
  position: relative;
  text-align: center;
}
</style>
