<template>
    <div class="foodspreview">
        <div class="foodspreview-wrapper">
            <div class="foodspreview-main">
                <FoodsCategory />
                <FoodCuisines />
                <FoodsTrending />
                <Promo />
                <FoodsExplore />
                <NewMerchants />
            </div>
        </div>
    </div>
</template>
<script>
import FoodCuisines from "@/components/common/preview/FoodCuisines.vue";
import FoodsExplore from "@/components/common/preview/FoodsExplore.vue";
import FoodsTrending from "@/components/common/preview/FoodsTrending.vue";
import NewMerchants from "@/components/common/preview/NewMerchants.vue";
import FoodsCategory from "@/components/common/preview/FoodsCategory.vue"
import Promo from "@/components/common/preview/Promo.vue"

import { mdiChevronRight } from '@mdi/js';


export default {
    name: 'FoodsPreview',
    components: {
        FoodCuisines,
        FoodsExplore,
        FoodsTrending,
        NewMerchants,
        FoodsCategory,
        Promo
    },
    data(){
        return{
            icons:{
                mdiChevronRight
            },
            orderBy: [
                { title: 'Price' },
                { title: 'Foods type' },
                { title: 'Most Recommended' },
                { title: 'Most Rated' },
            ],
        }
    },
    created() {
    },
    methods: {
    }
};
</script>

<style lang='scss'>
@import "../components/common/css/global.scss";

.foodspreview{
    overflow-x: hidden;
    
    .foodspreview-wrapper{
        /* max-width: 1600px; */
        /* margin: 0 auto; */
    }
    .foodspreview-main{
        /* padding: 0 40px; */
        /* margin: 40px 20px 40px; */

        /* @media screen and (max-width: 1600px){
            margin: 40px 0;
        }
        @media screen and (max-width: $bp-mobile){
            margin: 0;
            padding: 0 12px 20px;
        } */
    }


}
</style>