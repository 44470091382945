<template>
  <div
    id="header"
    class="visible__inactive"
    :class="{ visible__active: isVisibleStickyMenu }"
  >
    <van-overlay z-index="0" :show="overlay" @click="overlay = false" />
    <div
      class="white-overlay"
      :class="{ 'white-overlay__active': isVisibleStickyMenu, 'nav-overlay__active':drawer }"
    ></div>
    <div class="header-wrapper">
      <div class="logotype">
        <img
          :src="HomeLogo"
          alt="fastorder_logo"
          :style="overlay ? 'z-index:-1': ''"
        />
          <div class="logo-search" :class="{ 'logo-search_active': scrollActive, 'logo-search_hide':isBurgerActive}">
              <SearchBar :hideLogo="hideLogo" @activateOverlay="setOverlay" :overlay="overlay" />
          </div>
      </div>
      <div class="navigation" :style="overlay ? 'z-index:-1': ''">
        <ul class="navigation-menu">
          <li  :class="{'li__active': isVisibleStickyMenu}">
            <a href="/" :class="{'nav-linkText__active': isVisibleStickyMenu}">Menu</a>
          </li>
          <li :class="{'li__active': isVisibleStickyMenu}">
            <a href="/about" :class="{'nav-linkText__active': isVisibleStickyMenu}">About</a>
          </li>
          <li :class="{'li__active': isVisibleStickyMenu}">
            <a href="/#contact-us" :class="{'nav-linkText__active': isVisibleStickyMenu}">Contact Us</a>
          </li>
          <li :class="{'li__active': isVisibleStickyMenu}">
            <a href="/signin" :class="{'nav-linkText__active': isVisibleStickyMenu}">Join Us</a>
          </li>
          <span :class="{'nav-linkSeparator__active': isVisibleStickyMenu}"></span>
          <li :class="{'li__active': isVisibleStickyMenu}">
            <a href="/signin" :class="{'nav-linkText__active': isVisibleStickyMenu}">Sign In</a>
          </li>
        </ul>
        <button @click="burgerActive" class="navigation-burger">
          <ul class="burger-line">
            <li
              class="line-1"
              :class="{ 'line-1__active': isBurgerActive , 'line-overlay__active': isVisibleStickyMenu}"
            ></li>
            <li
              class="line-2"
              :class="{ 'line-2__active': isBurgerActive , 'line-overlay__active': isVisibleStickyMenu}"
            ></li>
            <li
              class="line-3"
              :class="{ 'line-3__active': isBurgerActive, 'line-overlay__active': isVisibleStickyMenu }"
            ></li>
          </ul>
          <div class="nav-text-menu">Menu</div>
        </button>
      </div>
    </div>

    <!-- Login pop up  -->
    <transition name="fadeTransition">
      <login v-if="isLoginShown" @close="toggleLogin" />
    </transition>

    <v-navigation-drawer
              v-model="drawer"
              fixed
              right
              hide-overlay
              stateless
              width="100%"
              class="sidebar-drawer"
              :disable-resize-watcher="true"
            >
              <v-list
                nav
                dense
              >
                <v-list-item-group>
                  <div>
                      <ul class="sidebar-nav">
                        <div class="sidebar-close-icon" @click="burgerActive">
                          <v-icon large color="#ee2d24">
                            {{icons.mdiClose}}
                          </v-icon>
                        </div>
                        <li>
                          <a href="/">Menu</a>
                        </li>
                        <li>
                          <a href="/about">About</a>
                        </li>
                        <li>
                          <a href="/#contact-us">Contact Us</a>
                        </li>
                        <li>
                          <a href="/signin">Join Us</a>
                        </li>
                        <li>
                          <a href="/signin">Sign In</a>
                        </li>
                      </ul>

                      <div class="sidebar-footer">
                          <p class="copyright">© Copyright 2021 FastOrder.my</p>
                          <div class="social">
                            <a href="https://www.facebook.com/fastorder.my"
                              ><font-awesome-icon :icon="['fab', 'facebook']" class="icon alt"
                            /></a>
                            <a href="https://www.instagram.com/fastorder.my/"
                              ><font-awesome-icon :icon="['fab', 'instagram']" class="icon alt"
                            /></a>
                          </div>
                      </div>
                  </div>
                  
                </v-list-item-group>
              </v-list>
      </v-navigation-drawer>
  </div>
</template>
<script>
import Login from "./Login.vue";
import { mdiClose ,mdiMagnify} from '@mdi/js';
import HomeLogo from '../../assets/logo2.png'
import SearchBar from './SearchBar.vue'


export default {
  name: "TopNavbar",
  components: { Login,SearchBar },
  props :{
    isMobile: {
      Boolean,
    },
    scrollActive: {
      Boolean,
    }
  },
  data() {
    return {
      overlay:false,
      hideLogo:true,
      searchQuery: "",
      icons:{
        mdiMagnify,
        mdiClose,
      },
      HomeLogo,
      drawer: false,
      isBurgerActive: false,
      isVisibleStickyMenu: false,
      isLoginShown: false,
    };
  },
  created() {
    if(this.$route.name !== "Home"){
        this.isVisibleStickyMenu = true;
    }else{
      if(!this.isMobile){
          window.addEventListener("scroll", this.handleScroll);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch : {
    scrollActive: function(){
      if(this.scrollActive !== true){
        this.overlay=false;
      }
    },
    drawer : function(){
      const body = document.getElementsByTagName('html')[0];
      if(this.drawer){
        body.classList.add("overflow-hidden");
      }else {
        body.classList.remove("overflow-hidden");
      }
    },
    isMobile: function(){
      if(!this.isMobile){
        window.addEventListener("scroll", this.handleScroll);
      }
    }
  },
  methods: {
    setOverlay(){
      this.overlay= true;
    },
    throttle(callback,limit){
      let wait = false;
      return function(){
        if(!wait){
          callback.call();
          wait =true;
          setTimeout(function (){
            wait= false;
          },limit)
        }
      }
    },
    burgerActive() {
      this.drawer = !this.drawer
      this.isBurgerActive = !this.isBurgerActive;
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.isVisibleStickyMenu = true;
      } else {
        this.isVisibleStickyMenu = false;
      }
    },

    toggleLogin() {
      console.log(this.isLoginShown);
      this.isLoginShown = !this.isLoginShown;
    },
  },
};
</script>
<style lang="scss">
@import "./css/animation.scss";
@import "./css/global.scss";
@import "./css/common.scss";


#header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: 1s ease-in-out;
  /* background: linear-gradient(180deg, rgba(2, 2, 2, 0.3) 60%,rgba(0, 0, 0, 0.01)); */

  transition: all 0.2s ease-out;
  @media screen and (max-width: $bp-mobile){
    position: static;
    background: none;
  }
  .white-overlay {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* inset: 0;  // not working in safari */
    background: $white;
    height: 100%;
    width: 100%;
    transform: translateY(-100%);
    transition: all 0.2s cubic-bezier(0.22, 0.99, 0.5, 0.97);
  }
  .white-overlay__active {
    transform: translateY(0);
    box-shadow: 0 0px 10px 0 darkgray;
  }
  .nav-overlay__active{
    transform: translateY(0);
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 95px;
    max-width: 1600px;
    width: 100%;
    padding: 0 5rem;
    @media screen and (max-width: 900px) {
      padding: 0 2rem;
    }
    @media screen and (max-width: $bp-mobile) {
      height: 56px;
      padding: 0 1rem;
    }
  }
  .logotype {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    
    img{
      cursor: pointer;
      display: block;
      height: 50px;
      z-index: 2;
      @media screen and (max-width: $bp-mobile) {
        height: 32px;
      }
    }

    .logo-search{
      /* margin-left: 20px; */
      max-width: 500px;
      width: 100%;
      z-index: -14;
      opacity: 0;
      margin-left: -20px;
      transition:all 0.2s cubic-bezier(.04,1.03,.66,.98);
      @media screen and (max-width: $bp-mobile) {
        display: none;
      }
    }
    .logo-search_active{
      margin-left: 20px;
      z-index: 1;
      opacity: 1;
    }
    .logo-search_hide{
      display: none;
    }
  }
  .navigation{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    @media screen and (max-width: 1280px) {
      width: fit-content;
      margin-left: 40px;
    }
  }
  .navigation-menu {
    display: flex;
    padding: 0;
    align-items: center;
    li {
      position: relative;
      list-style: none;
      margin: 0 14px;
    }
    
    li::after{
      content: "";
      position: absolute;
      height: 3px;
      width:  100%;
      background: $bgColor3;
      left: 0;
      right: 0;
      bottom: -5px;
      transition: 0.3s cubic-bezier(.06,.81,.28,1.29);
      transform: scale(0);
    }
    .li__active::after{
      background: $bgColor2;
    }
    li:hover::after{
      transform: scale(1);
    }
    a {
      font-family: roboto;
      text-decoration: none;
      color: $white;
      font-size: 22px;
      font-weight: 600;
      white-space: nowrap;
    }
    .nav-linkText__active{
      color: $gray2;
    }
    span{
      height: 20px;
      width: 2px;
      background-color: $bgColor3;
    }
    .nav-linkSeparator__active{
      background: $bgColor2;
    }

    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
  .navigation-burger {
    display: none;

    .burger-line {
      transform: scale(0.8);
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 10px 6px;
      li {
        margin: 2px 0;
        height: 4px;
        background: $bgColor3;
        width: 36px;
        transition: 0.2s ease-in;
        background-color: $bgColor3;
        @media screen and (max-width: $bp-mobile) {
          background-color: $bgColor2;
          width: 30px;
        }
      }
      .line-overlay__active{
        background: $bgColor2;
      }
      .line-1__active {
        transform: rotate(45deg) translate(6px, 5px);
        background-color: $bgColor2;
      }
      .line-2__active {
        opacity: 0;
      }
      .line-3__active {
        transform: rotate(-45deg) translate(6px, -6px);
        background-color: $bgColor2;
      }

      @media screen and (max-width: $bp-mobile) {
        transform: scale(0.6);
      }
    }

    .nav-text-menu{
      display: none;
      @media screen and (max-width: $bp-mobile){
        display: block;
        color: $red1;
      }
    }
    @media screen and (max-width: 1280px) {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: $bp-mobile) {
      height: 40px;
      width: 100px;
      box-shadow: 0.5px 0.5px 1px 0 $red1;
      border-radius: 3px;
    }
  }
  .sidebar-drawer{
    text-align: center;
    margin-top:94px !important;
    height: 100vh !important;
    z-index: 20;
    @media screen and (max-width: $bp-mobile) {
      margin-top: 0 !important;
    }
  }
  .sidebar-nav{
    padding: 1rem 1rem 2rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $white1;
    @media screen and (max-width: $bp-mobile){
      padding-top: 3rem;
      position: relative;
    }
    li{
      list-style: none;
      margin: 5px 0;
    }
    a{
      display: block;
      text-decoration: none;
      padding: 8px 10px;
      color: $gray5;
      min-width: 200px;
    }
    a:hover{
      background-color: rgb(230, 230, 230);
    }
    .sidebar-close-icon{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      display: none;
      @media screen and (max-width: $bp-mobile){
        display: block;
      }
    }
  }
  .sidebar-footer{
    color: $gray5;
    margin-top: 2rem;

    .copyright{
      font-size: 12px;
      color: $color1 ;
    }
    .social{
      margin-top: 2rem;
        a{
          text-decoration: none;
          color: rgb(125 122 158);
          margin: 10px 20px;
          padding: 10px;
          font-size: 16px;
        }
        .icon {
          font-size: 30px;
          opacity: 0.8;
        }
    }
  }
}
</style>
