<template>
    <div class="foods-explore">
        <div class="foods-explore-wrapper">

            <div class="preview-explore">
                    <h1>Explore more</h1>
                </div>

            <div class="food-explore-main">
                <div v-for="food in foodsList" :key="food.id" class="food-card">
                    <div class="food-title">
                        <span>{{ food.name}}</span>
                        <div v-if="food.stores.length>0">
                            <div class="view-button" @click="handleCuisine(food)">
                                <span>See All</span>
                                <v-icon>
                                    {{icons.mdiChevronRight}}
                                </v-icon>
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <div v-if="food.stores.length>0" class="foods-image-wrapper">
                            <div v-for="store in food.stores.slice(0,4)" :key="store.id">
                                <div class="food-box-wrappper">
                                    <img :src="store.thumbnail" alt="img" >
                                </div>
                            </div>
                        </div>
                        <div v-else style="display:flex;font-size:12px">
                            No stores available 
                        </div>
                            <!-- <div class="foods-image-wrapper">
                            <div v-for="store in 4" :key="store.id">
                                <div class="food-box-wrappper">
                                    <img src="https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpg" alt="img" >
                                </div>
                            </div>
                        </div> -->
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mdiChevronRight } from '@mdi/js';
import demo from "../../../db/test"
import { getStoreTag } from '@/components/api/api.js'

export default{
    name:'FoodsExplore',
    data(){
        return{
            isMobile: false,
            icons:{
                mdiChevronRight
            },
            foodsList:[],
            foods_list:[]
        }
    },
    created(){
        this.getData()
        this.listenToResize()
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.listenToResize);
    },
    watch:{
        isMobile :function(){
            if(this.isMobile){
                this.foodsList = this.foods_list.slice(0,4)
            }else{
                this.foodsList = this.foods_list
            }
        }
    } ,
    methods: {
        throttle(callback,limit){
            let wait = false;
            return function(){
                if(!wait){
                callback.call();
                wait =true;
                setTimeout(function (){
                    wait= false;
                },limit)
                }
            }
        },
        handleCuisine(food){    
            let query = food.name.toLowerCase()
            let url= {
                path: `/explore/${query}`
            }
            this.$router.push(url)
        },
        listenToResize() {
            if(window.innerWidth <641){
                this.isMobile =true
            }else{
                this.isMobile=false
            }
        },    
        getData(){
            getStoreTag()
                .then(res => {
                    console.log('store tag =>', res.data)
                    const indexpage = res.data
                    this.foods_list = indexpage
                    this.foodsList = this.foods_list
                }).catch(error => {
                    console.log('error :', error)
                })
            // console.log('res data =>', demo.foodsList)
            // const indexpage = demo.foodsList
            // this.foods_list = indexpage
        },
    }
}
</script>
<style lang="scss" scoped>
@import "../css/global.scss";
@import "../css/common.scss";

.foods-explore{
    display: none;
    padding: 0;
    .preview-explore{
        border-top: 1px solid $white4;
        padding: 20px 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: $bp-mobile){
            align-items: flex-start;
        }
        h1{
            font-size: 30px;
            color: $gray1;
            @media screen and (max-width: $bp-mobile){
                font-size: 16px;
                text-align: start;
            }
            
        }
    }
    .sort_by{
        display: flex;
        align-items: center;
        span{
            margin-right: 10px;
            font-weight: 500;
        }
        @media screen and (max-width: $bp-mobile){
            flex-direction: column;
            align-items: flex-end;
        }
    }
    .food-explore-main{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr) !important;
        align-items: center;
        justify-items: center;
        padding-bottom: 20px;
        grid-gap: 10px;
        grid-row-gap: 20px;
        @media screen and (max-width: 1280px){
            grid-template-columns: repeat(2,1fr) !important;
        }
        @media screen and (max-width: 1000px){
            grid-template-columns: repeat(1,1fr) !important;
        }
        @media screen and (max-width: $bp-mobile){
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
            grid-gap: 1rem !important;
        }
    }
    .food-card{
        width: 470px;
        height: 178px;
        background: rgb(250, 250, 250);
        box-shadow: 1px 1px 4px 0 rgba(169, 169, 169, 0.6);
        padding: 20px;
        transition: 0.1s ease-in;
        border-radius: 5px;

        @media screen and (max-width: 1500px){
            padding: 10px;
            max-width: 400px;
            width: 100%;
        }

        @media screen and (max-width: $bp-mobile){
            width: 100%;
            height: 129px;
            box-shadow: none;
            padding: 5px 0 10px;
            margin-bottom: 0;
        }
    }
    .food-card:hover{
        box-shadow: 5px 8px 5px 0 rgba(169, 169, 169);
        @media screen and (max-width: $bp-mobile){
            box-shadow: none;
        }
    }
    .food-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        span{
            font-size: 16px;
            font-weight: 600;
            color: $gray1;
            margin-left: 3px;
        }
        .view-button{
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .foods-image-wrapper::-webkit-scrollbar {
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
        
    }
    .foods-image-wrapper{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 10px;
        justify-items: center;

        @media screen and (max-width: 1500px){
            display: flex;
            overflow: scroll;
            grid-gap: 0;
            justify-content: space-between;
        }
        
        @media screen and (max-width: $bp-mobile){
            display: flex;
            align-items: center;
            overflow-x:scroll ;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
        }
    }
    }
    .food-box-wrappper{
        position: relative;
        cursor: pointer;
        height: 100px;
        width: 100px;
        @media screen and (max-width: 1500px){
            height: 90px;
            width: 90px;
        }
        @media screen and (max-width: $bp-mobile){
            height: 80px;
            width: 80px;
        }
        img{
            display: block;
            border-radius: 5px;
            height: 100%;
            width: 100%;
        }

    }
    .food-box-wrappper::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* inset: 0;  // not working in safari */
        width: 100%;
        height: 100%;
        background: rgba(10, 10, 10, 0.1);
    }
    .food-box-wrappper:hover:after{
        transition: 0.2s ease-in;
        background: rgba(158, 158, 158, 0.4);
    }

</style>