<template>
    <div class="loading">
        <div class="loading-wrapper">
            <img :src="Logo" alt="logo">
            <div class="loading-dots">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '../assets/logo2.png'

export default {
    name: "Banner",
    components: {
    },
    data() {
    return {
        Logo,
        }
    }
}
</script>

<style lang="scss">
@import "../components/common/css/common.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,500&display=swap');


.loading{
    /* background-image: linear-gradient(45deg, $purple0 0%, $purple0 100%); */
    background: $white;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
}
.loading-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;

    & img{
        height: 15rem;
        margin-bottom: 50px;
        @media screen and (max-width: 1000px){
            height: 10rem;
        }
        @media screen and (max-width: 480px){
            height: 5rem;
        }
    }
}
.loading-dots > div{
    height: 3rem;
    width: 3rem;
    background-color: $bgColor2;
    border-radius: 50%;
    display: inline-block;
    animation: 1.5s bounce infinite  ease-in-out both;
    @media screen and (max-width: 480px){
        height: 1.5rem;
        width: 1.5rem;
    }
}

.loading-dots .bounce1 {
    animation-delay: -0.3s ;
}

.loading-dots .bounce12{
    animation-delay: -0.15s ;
}

@keyframes bounce {
    0%,80%,100%{
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
</style>