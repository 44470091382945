<template>
  <div class="banner">
    <div class="banner-main">
      <div class="banner-wrapper">
        <div class="banner-section">
          <h2>What to eat without leaving home?</h2>
          <h2>We've got you covered. <span>FastOrder.my</span></h2>
        </div>

        <van-overlay z-index="0" :show="overlay" @click="overlay = false" />
        <div class="section-input" :class="{'section-input_active':scrollActive}" :style="overlay ? 'z-index:2' : ''">
          <SearchBar @activateOverlay="setOverlay" :overlay="overlay"/>
        </div>
      </div>
    </div>
    <div>
      
    </div>
  </div>
</template>
<script>
import axios from "axios"
import { mdiChevronRight,mdiMagnify,mdiCrosshairsGps } from "@mdi/js";
import SearchLogo from '../../assets/SearchLogo.jpg'
import SearchBar from './SearchBar.vue'

export default {
  name: "Banner",
  props: {
    scrollActive : {
      type: Boolean,
    }
  },
  watch: {
    scrollActive: function(){
      if(this.scrollActive == true){
        this.overlay=false;
      }
    }
  },
  components : {
    SearchBar
  },
  data() {
    return {
      overlay:false,
      query: '',
      SearchLogo,
      index : null,
      icons: {
        mdiChevronRight,
        mdiMagnify,
        mdiCrosshairsGps
      },
      results: [],
      searchQuery: "",
      showSearch: false,
      isLoading: true,
    };
  },
  methods:{
    setOverlay(){
      this.overlay= true;
    },
    onSelect(selected) {
      if (selected) {
        this.query = selected.item.address;
      }
    },
    indicesToSuggestions(indices) {
      return [
        { 
          data: indices[0].hits.filter(option => {
            return option.address.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          })
        }
      ];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.address;
    },
    handleLocation(result){
      console.log(result)
      this.searchQuery = result.address +', '+ result.city +', '+ result.state
      document.getElementById('search-button-2').focus();
    },  
    async inputSearch(){
      if(this.searchQuery != ""){
        // console.log(this.searchQuery)
        const result = await this.index.search(this.searchQuery);
        let hits = result.hits;
        console.log('results',hits)
        this.results = hits

        // this.search()
      }else{
        // this.results = ""
      }
    },
    search(searchType) {
      const vueComponent = this;
      var type = "";
      var query = this.searchQuery;

      this.isLoading = true;

      if (query == "") {
        this.showSearch = false;
        return;
      }

      this.showSearch = true;

      switch (searchType) {
        case "delivery":
          type = "&type=DELIVERY";
          break;
        case "takeaway":
          type = "&type=TAKEAWAY";
          break;
        default:
          type = "&type=DINEIN";
          break;
      }

      axios
        .get(
          "https://sb-api.fastorder.my/fomy/v2/store/list?name=" + query + type
        )
        .then(function (response) {
          console.log(response.data.data);
          vueComponent.results = response.data.data;
          vueComponent.isLoading = false;
        });
    },
  }
};
</script>

<style lang="scss">
@import "./css/global.scss";
@import "./css/common.scss";

.banner {
  background: url("~@/assets/HomePage2.jpg") center  no-repeat;
  background-size: cover;
  position: relative;
  padding: 95px 40px 0px 40px;
  height: 100vh;
  /* height: 800px; */
  @media screen and (max-width: 900px) {
    height: 700px;
  }
  @media screen and (max-width: $bp-mobile) {
    height: 100%;
    padding: 0;
  }
  .banner-main::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(59, 59, 59, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* inset: 0;  // not working in safari */
    z-index: 0;

    @media screen and (max-width: $bp-mobile) {
      background-color: rgba(49, 49, 49, 0.5);
    }
  }
  .banner-main > * {
    z-index: 10;
    position: relative;
  }
  .banner-main {
    height: 100%;
    width: 100%;
    padding: 8rem 6rem 0 10rem;

    @media screen and (max-width: 1500px) {
      padding: 4rem 0rem 0rem;
    }
    @media screen and (max-width: 1200px) {
      background-size: contain;
      background-position: right center;
    }
    @media screen and (max-width: $bp-mobile) {
      padding: 2rem ;
      background: none;
    }
  }

  .banner-wrapper{
    max-width: 1400px;
    margin: 0 auto;
    padding:0 20px;
      @media screen and (max-width: $bp-mobile) {
        padding: 0;
      }
  }

  .banner-section {
    margin-bottom: 20px;
    @media screen and (max-width: $bp-mobile) {
        margin-bottom: 0;
      }
    h2 {
      z-index: 10 !important;
      line-height: 70px;
      font-size: 3.5rem;
      color: $white1;
      text-align: left;
      font-family: roboto;
      span{
        font-family: roboto;
        color: whitesmoke;
      }
      @media screen and (max-width: 900px) {
        font-size: 2.5rem;
        line-height: 60px;
      }
      @media screen and (max-width: $bp-mobile) {
        font-size: 1.5rem;
        line-height: 35px;
      }
      @media screen and (max-width: 360px) {
        font-size: 1.2rem;
        line-height: 30px;
      }
    }
  }

  .section-input{
    max-width: 650px;
    display: block;
    margin: 10px 0 140px;
    transform: scalex(1) translateY(0);
    transform-origin: 5% 10%;
    transition: all .3s ease-in;

    @media screen and (max-width: $bp-mobile) {
      display: none;
    }
  }

  .section-input_active{
    transform: scalex(0.2) translateY(-100px);
    opacity: 0;
    z-index: -10;
  }
  
}
</style>