<template>
    <div class="navfooter" :class="{ 'navfooter_isHome': isHome, 'navfooter_isHome_active': scrollActive}">
        <div class="navfooter-wrapper">
            <div class="navfooter-main">
                <!-- <a href="/" class="navfooter-logo">
                    <v-icon color="#ffcf44">{{icons.mdiHome}}</v-icon>
                    <div class="navfooter-logo-name">Home</div>
                </a>
                <a href="/account" class="navfooter-logo"> 
                    <v-icon color="#d9d9d9">{{icons.mdiClipboardText }}</v-icon>
                    <div class="navfooter-logo-name">Join Us</div>
                </a>
                <a href="/account" class="navfooter-logo">
                    <v-icon color="#d9d9d9">{{icons.mdiAccount}}</v-icon>
                    <div class="navfooter-logo-name">Login</div>
                </a> -->

                <a href="/" class="navfooter-logo">
                    <v-icon :color="page=='' ? '#ffcf44' : '#d9d9d9'">{{icons.mdiSilverwareForkKnife}}</v-icon>
                    <div class="navfooter-logo-name">Browse</div>
                </a>
                <a href="/orders" class="navfooter-logo">
                    <v-icon :color="page=='orders' ? '#ffcf44' : '#d9d9d9'">{{icons.mdiFileTable}}</v-icon>
                    <div class="navfooter-logo-name">Orders</div>
                </a>
                <a href="/favorites" class="navfooter-logo">
                    <v-icon :color="page=='favorites' ? '#ffcf44' : '#d9d9d9'">{{icons.mdiAccount}}</v-icon>
                    <div class="navfooter-logo-name">Favourite</div>
                </a>
                <a href="/notification" class="navfooter-logo">
                    <v-icon :color="page=='notification' ? '#ffcf44' : '#d9d9d9'">{{icons.mdiBell}}</v-icon>
                    <div class="navfooter-logo-name">Notification</div>
                </a>
                <a href="/account" class="navfooter-logo">
                    <v-icon :color="page=='account' ? '#ffcf44' : '#d9d9d9'">{{icons.mdiAccount}}</v-icon>
                    <div class="navfooter-logo-name">Account</div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { 
    mdiHome,
    mdiAccount,
    mdiClipboardText,
    mdiSilverwareForkKnife,
    mdiFileTable,
    mdiHeart,
    mdiBell,
} from '@mdi/js';

export default {
    name: "NavFooter",
    props: {
        isHome: {
            type:Boolean
        },
        scrollActive:{
            type:Boolean
        }
    },
    data() {
        return {
            icons: {
                page:'',
                mdiHome,
                mdiAccount,
                mdiClipboardText,
                mdiSilverwareForkKnife,
                mdiFileTable,
                mdiHeart,
                mdiBell,
            }
        }
    },
    watch:{
        scrollActive:function(){
            if(this.scrollActive == true){
                //
            }
        }
    },
    created(){
        this.page= window.location.pathname.replace(/\//g, '')
    },
    
}
</script>

<style lang="scss">
@import "@/components/common/css/global.scss";
@import "@/components/common/css/common.scss";


.navfooter_isHome{
    transform: translateY(100%);
    transition: 0.3s ease-in-out;
}
.navfooter_isHome_active{
    transform: translateY(0);
}
.navfooter{
    display: none;
}

@media screen and (max-width: $bp-mobile){
    .navfooter{
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $rgb-bgColor2;
        z-index: 3;
        
    }
    .navfooter-main{
        display: flex;
        justify-content: space-around;
        padding: 8px 0 3px;
        height: 50px;

        & .navfooter-logo{
            display: flex;
            flex-direction: column;
            align-items: center;


            & .navfooter-logo-name{
                color: $white;
                font-size: 10px;
            }
        }
    }
}





</style>