<template>
  <div id="contact-us">
    <div class="contact-us__left">
      <h1>CONTACT US</h1>
      <div class="contact-us__info">
        <h3>Head office address</h3>
        <p>
          B-01-03, Garden Shoppe @ One City, Jalan USJ 25/1A, 47650 Subang Jaya,
          Selangor, Malaysia
        </p>
      </div>
      <div class="contact-us__info">
        <h3>Call for help</h3>
        <p>+603 5889 0354 (Sales)</p>
        <p>+6011 5924 9880 (Support)</p>
      </div>
      <div class="contact-us__info">
        <h3>Mail us</h3>
        <p>hello@fastorder.my</p>
        <p>support@fastorder.my</p>
      </div>
    </div>
    <div class="contact-us__right">
      <div class="contact-form">
        <h2>Get in Touch</h2>
        <v-text-field
          class="contact-form__field"
          dense
          label="First name"
          persistent-hint
          filled
          background-color="rgba(0,0,0,0.08)"
        ></v-text-field>
        <v-text-field
          class="contact-form__field"
          dense
          label="Last name"
          persistent-hint
          filled
          background-color="rgba(0,0,0,0.08)"
        ></v-text-field>
        <v-text-field
          class="contact-form__field"
          dense
          label="Email"
          persistent-hint
          filled
          background-color="rgba(0,0,0,0.08)"
        ></v-text-field>
        <v-text-field
          class="contact-form__field"
          dense
          label="Phone Number"
          persistent-hint
          filled
          background-color="rgba(0,0,0,0.08)"
        ></v-text-field>
        <v-textarea
          class="contact-form__field"
          dense
          label="Phone Number"
          persistent-hint
          filled
          background-color="rgba(0,0,0,0.08)"
        ></v-textarea>
        <button class="btn-hero btn-red">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="scss" scoped>
@import "../components/common/css/global.scss";
@import "../components/common/css/button.scss";
@import "@/components/common/css/common.scss";

#contact-us {
  background-color: $bgColor2;
  color: $white;
  padding: 10px;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  @media (max-width: $bp-mobile) {
    padding-top: 20px;
  }
  & h1 {
    display: table;
    width: 300px;
    text-align: left;
    margin: 20px;

    // For smaller screens
    @media (max-width: $bp-tablet) {
      text-align: center;
      width: 100%;
      margin-left: 0px;
    }
  }

  // For smaller screens
  @media (max-width: $bp-tablet) {
    flex-direction: column;
    text-align: center;
  }
}

.contact-us__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;

  .contact-us__info {
    margin: 20px;
    width: 300px;
    text-align: left;

    & h3 {
      font-weight: 500;
      font-size: 20px;
    }

    & p {
      font-weight: 200;
      font-size: 16px;
      margin: 0;
    }

    // For smaller screens
    @media (max-width: $bp-tablet) {
      text-align: center;
      padding: 10px;
      margin-top: 0px;
      margin-left: 0px;
      width: 100%;
    }
  }

  // For smaller screens
  @media (max-width: $bp-tablet) {
    width: 100%;
  }
}

.contact-us__right {
  display: flex;

  .contact-form {
    text-align: left;
    padding: 30px;
    // border-radius: 15px;
    background-color: $white;
    color: $black;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-wrap: wrap;
    min-width: 200px;
    max-width: 600px;

    & h2 {
      margin-bottom: 10px;
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  // For smaller screens
  @media (max-width: $bp-tablet) {
    text-align: center;
    width: 100%;
    justify-content: center;
  }
}

.contact-form__field {
  width: 200px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;

  & ::v-deep {
    & .v-input__slot::before {
      border-style: none !important;
    }
    & .v-input__slot {
      border-radius: 0px;
    }
  }
}

.btn-hero {
  text-align: center;
  border-radius: 0;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
